import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import moment from 'moment';
import {isNil} from 'lodash';

class SitesApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/search-console/api/v2');
  private static readonly baseUrlNew: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/search-console/api/v3');
  private static readonly baseUrlSearchAtlas: string = 'https://gsc.searchatlas.com/search-console/api/v3';
  private static readonly baseUrlBL: string = getApiUrl(BaseApi.BACKLINK_ENDPOINT, '/backlink');
  private static readonly baseUrlLG: string = getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api');
  private static readonly baseUrlKE: string = getApiUrl(BaseApi.KEYWORD_ENDPOINT, '/api');

  public async getSites(gscAdminCode='', includeReports=false, pageSize=10, pageNumber=1, countryCode, active?: boolean, payload?: any) {
    const getToken = localStorage.getItem('token');
    const queryParam = {
      page_number: pageNumber,
      page_size: pageSize,
      ...(countryCode && !isNil(countryCode) && {country_code: countryCode}),
      order_by: payload?.ordering,
      search: payload?.search,
    };
    if ([true, false].includes(active)) {
      queryParam['active'] = active;
    }

    if (gscAdminCode) {
      queryParam['gsc_admin_code'] =gscAdminCode;
    }
    const currentPeriodStart = payload?.date ? payload.date : moment().subtract(1, 'years').format('YYYY-MM-DD');
    const currentPeriodEnd = payload?.currentPeriodEnd ? moment(payload?.currentPeriodEnd) : moment();
    const diff = moment(currentPeriodEnd).diff(moment(currentPeriodStart), 'days') + 1;
    const previousPeriodStart = moment(currentPeriodStart).subtract(1, 'days').subtract(diff, 'days');
    const previousPeriodEnd = moment(currentPeriodStart).subtract(1, 'days');
    const period1Start = previousPeriodStart.format('YYYY-MM-DD');
    const period1End = previousPeriodEnd.format('YYYY-MM-DD');
    const period2Start = moment(currentPeriodStart).format('YYYY-MM-DD');
    const period2End = currentPeriodEnd.format('YYYY-MM-DD');
    if (currentPeriodStart) {
      queryParam['period1_start'] = period1Start;
      queryParam['period1_end'] = period1End;
      queryParam['period2_start'] = period2Start;
      queryParam['period2_end'] = period2End;
    }

    if (getToken) {
      try {
        const response = await this.axios.get(`${SitesApi.baseUrlSearchAtlas}/sites/${('active' in queryParam || includeReports) ? 'reports/' : ''}`, {
          ...(('active' in queryParam || includeReports) && {params: queryParam}),
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
        );
        return response.data;
      } catch (e) {
        return Promise.reject(e);
      }
    }
  }
  public async getDomainRating(website:string) {
    const response = await this.axios.get(`${SitesApi.baseUrlBL}/website-stats/?url=${website}`, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async refetchSites() {
    try {
      const response = await this.axios.get(`${SitesApi.baseUrl}/refetch-sites/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return {};
    }
  }

  public async siteProperties() {
    try {
      const response = await this.axios.get(`${SitesApi.baseUrl}/customer/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async deactivateSite(payload) {
    try {
      const response = await this.axios.patch(`${SitesApi.baseUrlNew}/customer/deactivate-siteproperties/`,
        {siteproperties: payload.map(item => {
          return {
            id: item.id,
            country_codes: item.countryCode,
            site_url: item?.site_url,
          };
        })}, {headers: {Authorization: getAuthorizationHeader()}, cancelToken: this.cancelToken},
      );
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  // const response = await this.axios.patch(`${SitesApi.baseUrl}/customer/activate-siteproperties/`,
  // this is the old API for activating sites from manage projects drawer, should be removed and cleaned up
  public async activateSite(payload) {
    try {
      const response = await this.axios.patch(`${SitesApi.baseUrlNew}/customer/activate-siteproperties/`,
        {siteproperties: payload.map(item => {
          return {
            id: item.id,
            country_codes: item.countryCode,
            site_url: item?.site_url,
          };
        })}, {headers: {Authorization: getAuthorizationHeader()}, cancelToken: this.cancelToken},
      );
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async activateSiteV2(payload) {
    try {
      const response = await this.axios.patch(`${SitesApi.baseUrlNew}/customer/update-siteproperties/`,
        {siteproperties: payload.map(item => {
          return {
            id: item.id,
            country_codes: item.country_codes,
            site_url: item?.site_url,
          };
        })}, {headers: {Authorization: getAuthorizationHeader()}, cancelToken: this.cancelToken},
      );
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async activateSiteV3(payload) {
    try {
      const response = await this.axios.patch(`${SitesApi.baseUrlNew}/customer/update-siteproperties-v2/`,
        {siteproperties: payload.map(item => {
          return {
            id: item.id,
            country_codes: item.country_codes,
            site_url: item?.site_url,
          };
        })}, {headers: {Authorization: getAuthorizationHeader()}, cancelToken: this.cancelToken},
      );
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async archiveSite(sitePropertyId) {
    try {
      const response = await this.axios.patch(`${SitesApi.baseUrl}/customer/archive-siteproperty/`,
        {siteproperty_id: sitePropertyId,
        }, {headers: {Authorization: getAuthorizationHeader()}, cancelToken: this.cancelToken},
      );
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async archiveGscSites(sitePropertyId) {
    try {
      const response = await this.axios.patch(`${SitesApi.baseUrl}/customer/archive-siteproperty/`,
        {siteproperty_id: sitePropertyId}, {headers: {Authorization: getAuthorizationHeader()}, cancelToken: this.cancelToken},
      );
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async unArchiveGscSites(sitePropertyId) {
    try {
      const response = await this.axios.patch(`${SitesApi.baseUrl}/customer/unarchive-siteproperty/`,
        {siteproperty_id: sitePropertyId}, {headers: {Authorization: getAuthorizationHeader()}, cancelToken: this.cancelToken},
      );
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getSearchPerformanceStatus() {
    const url = `${SitesApi.baseUrl}/search-performance-status/`;
    const response = await this.axios.get(url, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      // cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getEmailSettingv2(id: number, selectedCountryCode: string) {
    const response = await this.axios.get(`${SitesApi.baseUrl}/customer/email-settings/${id}/?country_code=${selectedCountryCode}`,
      {headers: {Authorization: getAuthorizationHeader()}},
    );
    return response.data;
  }
  public async updateEmailSettingsV2(id: number, data, selectedCountryCode: string) {
    const response = await this.axios.patch(`${SitesApi.baseUrl}/customer/email-settings/${id}/update/?country_code=${selectedCountryCode}`,
      {settings: data},
      {headers: {Authorization: getAuthorizationHeader()}},
    );
    return response.data;
  }

  public async getCustomerEmailSetting() {
    const response = await this.axios.get(`${SitesApi.baseUrlLG}/customer/account/email-settings/`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      },
    );
    return response.data;
  }

  public async updateCustomerEmailSetting(id: number, payLoad: any) {
    const response = await this.axios.put(`${SitesApi.baseUrlLG}/customer/account/email-settings/${id}/`,
      payLoad,
      {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      },
    );
    return response.data;
  }

  public async updateBulkCustomerEmailSetting(id, action: string) {
    const response = await this.axios.post(`${SitesApi.baseUrlLG}/customer/account/email-settings/${id}/bulk-action/`,
      {'action': action},
      {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      },
    );
    return response.data;
  }

  public async getKECustomerEmailSetting() {
    const response = await this.axios.get(`${SitesApi.baseUrlKE}/v1/customer/account/email-settings/`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      },
    );
    return response.data;
  }

  public async updateKECustomerEmailSetting(id: number, payLoad: any) {
    const response = await this.axios.put(`${SitesApi.baseUrlKE}/v1/customer/account/email-settings/${id}/`,
      payLoad,
      {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      },
    );
    return response.data;
  }

  public async getSearchConsoleEmailSettingList() {
    const response = await this.axios.get(`${SitesApi.baseUrlSearchAtlas}/email-config/`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      },
    );
    return response.data;
  }

  public async getSearchConsoleList() {
    const response = await this.axios.get(`${SitesApi.baseUrlSearchAtlas}/email-config/sites-dropdown`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      },
    );
    return response.data;
  }

  public async updateSearchConsoleCustomerEmailSetting(id: number, payLoad: any) {
    const response = await this.axios.patch(`${SitesApi.baseUrlSearchAtlas}/email-config/${id}/`,
      payLoad,
      {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      },
    );
    return response.data;
  }

  public async getOutreachCampaignsList() {
    const response = await this.axios.get(`${SitesApi.baseUrlKE}/v1/customer-outreach/campaigns/dropdown/`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      },
    );
    return response.data;
  }

  public async getGoogleBusinessList() {
    const response = await this.axios.get(`${SitesApi.baseUrlKE}/v3/google-business/dropdown/`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      },
    );
    return response.data;
  }

  public async getCompetitorResearchList() {
    const response = await this.axios.get(`${SitesApi.baseUrlKE}/v2/competitor-research/dropdown/`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      },
    );
    return response.data;
  }

  public async getAggregatorNetworkList() {
    const response = await this.axios.get(`${SitesApi.baseUrlKE}/v1/aggregator-network/dropdown/`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      },
    );
    return response.data;
  }
}
export const SITES_API = new SitesApi();
export default SitesApi;
