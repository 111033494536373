import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleCheck, faCircleExclamation, faTriangleExclamation} from '@fortawesome/pro-duotone-svg-icons';
import {faPen} from '@fortawesome/pro-solid-svg-icons';
import {Tooltip} from 'antd';
import moment from 'moment';
import {observer} from 'mobx-react';
import FreezeWrapper from '../../../freezTooltip';
import {PopupContainer, IntervalLabel, DayLabel, IntervalsContainer} from '../styles';

const OpenHoursField = observer(({field, openModal, value}) => {
  const [suggestion, setSuggestion] = useState();
  const [currentValue, setCurrentValue] = useState<any>();
  const [valueArray, setValueArray] = useState([]);
  const [iconDetails, setIconDetails] = useState({
    color: '#F44343',
    icon: faCircleExclamation,
  });

  useEffect(() => {
    if (value?.length) {
      setValueArray(value.filter(item => item.fieldName == field.fieldName));
    }
  }, [value]);

  useEffect(() => {
    valueArray?.length && setValues();
  }, [valueArray]);

  const setValues = () => {
    setSuggestion(valueArray?.find(item => item.source == 'OTTO_AI' && item.eventType !== 'LOCK_FIXED'));
    const values = valueArray.filter(item => item.fieldName == field.fieldName && (item.source == 'OTTO' || (item.source == 'GBP')));
    const latest = values?.reduce((latestObj, currentObj) => {
      return new Date(currentObj?.createdAt) > new Date(latestObj?.createdAt) ? currentObj : latestObj;
    });
    setCurrentValue(latest);
  };

  useEffect(() => {
    const isValueNull = currentValue?.value && Object.values(currentValue?.value)?.some((item: any) => item.intervals?.length);
    if (isValueNull) {
      if (suggestion) {
        setIconDetails({
          color: '#FF8536',
          icon: faTriangleExclamation,
        });
      } else {
        setIconDetails({
          color: '#2AC155',
          icon: faCircleCheck,
        });
      }
    } else {
      setIconDetails({
        color: '#F44343',
        icon: faCircleExclamation,
      });
    }
  }, [currentValue?.value]);

  const handlePopupClick = e => {
    e.preventDefault();
    e.stopPropagation();
    openModal(suggestion, currentValue?.value);
  };


  const getInterval = (interval: any) => {
    if (interval?.close == '24') {
      return '24 hours';
    } else {
      return `${moment(interval?.open, 'HH:mm').format('hh:mm A')} - ${moment(interval?.close, 'HH:mm').format('hh:mm A')}`;
    }
  };

  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  return (
    <>
      <Tooltip title='No possible Suggestion' overlayInnerStyle={{backgroundColor: '#121212', borderRadius: '8px', border: '1px solid #121212'}}>
        <FontAwesomeIcon
          style={{marginRight: 6, marginTop: '5px'}}
          color={iconDetails.color}
          icon={iconDetails.icon}
        />
      </Tooltip>
      <PopupContainer style={{width: 'calc(100% - 60px)'}}>
        <div>
          {days.map(item => (
            <IntervalLabel key={item}>
              <DayLabel>
                <span>{item}</span>
              </DayLabel>
              <IntervalsContainer>
                {currentValue?.value?.[item.toLowerCase()]?.intervals?.length ? (
                  currentValue?.value?.[item.toLowerCase()]?.intervals?.map((interval, idx) => (
                    <div key={item + '_' + idx}>{getInterval(interval)}</div>
                  ))
                ) : (
                  <div>Closed</div>
                )}
              </IntervalsContainer>
            </IntervalLabel>
          ))}        </div>
      </PopupContainer>
      <div className='edit-icon'>
        <FreezeWrapper>
          <Tooltip title='Edit'>
            <div onClick={e => handlePopupClick(e)}>
              <FontAwesomeIcon
                style={{marginRight: 6}}
                icon={faPen}
                color='#2D6CCA'
              />
            Edit
            </div>
          </Tooltip>
        </FreezeWrapper>
      </div>
    </>
  );
});

export default OpenHoursField;

