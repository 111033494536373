import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleCheck, faTriangleExclamation} from '@fortawesome/pro-duotone-svg-icons';
import {Tooltip} from 'antd';
import {isArray} from 'lodash';
import {faPen} from '@fortawesome/pro-solid-svg-icons';
import {Attribute, AttributeContainer, AttributeOverflow, PopupContainer} from '../styles';
import {humanize} from '@/utils/string';
import FreezeWrapper from '../../../freezTooltip';

const AttributeField = ({openModal, value, attributes}) => {
  const [valueArray, setValueArray] = useState<any>([]);
  const [suggestion, setSuggestion] = useState([]);
  const [currentValue, setCurrentValue] = useState<any>([]);

  useEffect(() => {
    if (value?.length) {
      setValueArray(value.filter(item => (item.fieldType == 'ATTRIBUTE' && attributes.includes(item.fieldName))));
    }
  }, [JSON.stringify(value), attributes]);

  useEffect(() => {
    valueArray?.length && setValues();
  }, [valueArray]);

  const setValues = () => {
    setSuggestion(valueArray?.filter(item => item.source == 'OTTO_AI' && item.eventType !== 'LOCK_FIXED'));
    const valueObj = valueArray?.filter(item => item.source == 'OTTO');
    valueArray.forEach(item => {
      const ottoFieldIdx = valueObj.findIndex(data => data.fieldName == item.fieldName);
      if (item.source == 'GBP' && ottoFieldIdx == -1) {
        valueObj.push(item);
      } else if (item.source == 'GBP' && ottoFieldIdx !== -1) {
        if (new Date(item?.createdAt) > new Date(valueObj[ottoFieldIdx]?.createdAt)) {
          valueObj.splice(ottoFieldIdx, 1);
          valueObj.push(item);
        }
      }
    });
    setCurrentValue(valueObj?.map(item => !isNull(item.value) && item)?.filter(item => item));
  };

  const getAttributeTitle = (key:string) => {
    const str = key?.replaceAll('attributes/', '');
    return humanize(str);
  };

  const getIconDetails = attrVal => {
    const hasVal = isArray(attrVal) ? attrVal?.length != 0 : attrVal;
    if (hasVal) {
      return {
        color: '#2AC155',
        icon: faCircleCheck,
      };
    } else {
      return {
        color: '#FF8536',
        icon: faTriangleExclamation,
      };
    }
  };

  const isNull = val => {
    if (!val) {
      return true;
    } else {
      if (typeof(val) == 'string' || isArray(val)) {
        return val?.length == 0;
      } else if (typeof(val) == 'object') {
        return Object.keys(val)?.length == 0;
      } else if (typeof(val) == 'boolean') {
        return !val;
      }
    }
  };

  const openLink = attr => {
    if (attr.fieldName.includes('url')) {
      window.open(attr?.value, '_blank');
    }
  };

  return (
    <>
      <PopupContainer style={{width: 'calc(100% - 60px)', cursor: currentValue?.length == 0 && 'pointer'}}>
        <AttributeOverflow>
          {currentValue?.length ? currentValue?.map((attr, idx) => (
            <div key={idx}>
              <Tooltip title='No possible Suggestions' overlayInnerStyle={{backgroundColor: '#121212', borderRadius: '8px', border: '1px solid #121212'}}>
                <FontAwesomeIcon
                  style={{marginRight: 6}}
                  color={getIconDetails(attr.value).color}
                  icon={getIconDetails(attr.value).icon}
                />
              </Tooltip>
              <span>{getAttributeTitle(attr.fieldName)}</span>
              <AttributeContainer style={{marginBottom: typeof(attr.value) == 'object' && 7}}>
                {(typeof(attr.value) == 'object' && isArray(attr.value)) && attr.value.map((item, i) => (
                  <Attribute isURL={attr?.fieldName?.includes('url')} onClick={() => openLink({fieldName: attr?.fieldName, value: item})} key={idx+ '-' + i}><span>{humanize(item)}</span></Attribute>
                ))}
                {(typeof(attr.value) == 'string') && (
                  <Attribute isURL={attr?.fieldName?.includes('url')} onClick={() => openLink(attr)}><span>{attr?.fieldName?.includes('url') ? attr.value : humanize(attr.value)}</span></Attribute>
                )}
              </AttributeContainer>
            </div>
          )) : (
            <>
              <FreezeWrapper>
                <Tooltip title={attributes?.length ? '' : 'No Attributes Available for this business'}>
                  <span style={{color: attributes?.length ? '#2D6CCA' : '#a3a4a4'}} onClick={() => attributes?.length && openModal(suggestion, currentValue)}>
                    <FontAwesomeIcon
                      style={{marginRight: 6}}
                      color='#FF8536'
                      icon={faTriangleExclamation}
                    />
                    Select Attributes
                  </span>
                </Tooltip>
              </FreezeWrapper>
            </>
          )}
        </AttributeOverflow>
      </PopupContainer>
      <div className='edit-icon' style={{color: !attributes?.length && '#a3a4a4'}}>
        <FreezeWrapper>
          <Tooltip title={attributes?.length ?'Edit' : 'No Attributes Available for this business'}>
            <div onClick={() => attributes?.length && openModal(suggestion, currentValue)}>
              <FontAwesomeIcon
                style={{marginRight: 6}}
                icon={faPen}
                color={attributes?.length ? '#2D6CCA' : '#a3a4a4'}
              />
          Edit
            </div>
          </Tooltip>
        </FreezeWrapper>
      </div>
    </>
  );
};

export default AttributeField;


