import {observer} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/pro-solid-svg-icons';
import {ButtonStyled, ButtonsWrapper, SectionsWrapper, Description, Heading, ModalStyled} from '../styledComponents';
import {ProjectSection} from './sections/projectSection';
import {KeywordsSection} from './sections/keywordsSection';
import {FiltersSection} from './sections/filtersSection';
import {EmailPresetSection} from './sections/emailPresetSection';
import {FollowUpEmailsSection} from './sections/followUpEmailsSection';
import {useStore} from '@/store/root-store';
import {useEffect, useState} from 'react';
import {Divider, Form} from 'antd';
import {EditorState} from 'draft-js';
import {convertToHtml} from '@/utils/editor-scripts';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import BlockListSection from './sections/blocklistSection';
import styled from 'styled-components';
import {notification} from '@/utils/notification-v2';
import {targetPageRegex, urlDomainRegex, urlSubDomainRegex} from '@/constants/regex';
import {useRouter} from 'next/router';
import {toJS} from 'mobx';
import {getSingleUrlParam} from '@/utils/url';
import {openUrl} from '@/utils/router';

interface DataProps {
  keyword: string;
  domains?: string[];
}
interface CreateCampaignModalProps {
  isInOTTO?: boolean;
  isInQuest?: boolean;
  data?: DataProps;
}
const tabsTitle = [
  'Integrations',
  'Email Present',
  'Follow-Up Emails',
];

export const CreateCampaignModal = observer(({isInOTTO, isInQuest, data}: CreateCampaignModalProps) => {
  const {campaigns: {
    postNewCampaign,
    createModalVisible,
    setCreateModalVisible,
    getExistingTemplates,
    setShowErrors,
    currentEditorState,
    setKeywordSuggestions,
    setCurrentEditorState,
    chartData,
    editCampaign,
    getChartData,
    postNewCampaignLoading,
    globalBlocklist,
    campaignBlocklist,
    updateCampaignBlockList,
    getCampaignsCustomerEmails,
    getCampaignsOpportunity,
    getCampaigns,
    campaignRepollingLoading,
    isEditModal,
  },
  ottoV2Store: {
    getOttoV2Project,
  },
  settings: {customer: {profile: {address}, getCustomerQuota}},
  } = useStore('');

  const [nameValue, setNameValue] = useState('');
  const [maxPage, setMaxPage] = useState<number | ''>(1);
  const [keywordsValue, setKeywordsValue] = useState('');
  const [seedValue, setSeedValue] = useState('');
  const [subjectValue, setSubjectValue] = useState('');
  const [useTemplateChecked, setUseTemplateChecked] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('US');
  const [modalSelectedItemV2, setModalSelectedItemV2] = useState(null);
  const [initialFiltersList, setInitialFiltersList] = useState([
    {id: 0, name: 'checkboxesFilter', header: 'TLDs', value: ['com', 'net', 'org', 'edu', 'gov', 'us'], customCategoryType: 'position', filterTypes: [
      {label: '.com', value: 'com'},
      {label: '.net', value: 'net'},
      {label: '.org', value: 'org'},
      {label: '.edu', value: 'edu'},
      {label: '.gov', value: 'gov'},
      {label: '.us', value: 'us'},
    ], type: undefined, active: false},
    {id: 1, name: 'domain-rating', header: 'Domain Rating', from: 0, to: 0, type: 'range', active: false},
    {id: 2, name: 'traffic', header: 'Traffic', from: 0, to: 0, type: 'range', active: false},
    {id: 3, name: 'keyword-count', header: 'Keyword Count', from: 0, to: 0, type: 'range', active: false},
    {id: 4, name: 'ref-domains', header: 'Ref. Domains', from: 0, to: 0, type: 'range', active: false},
  ]);
  const router = useRouter();
  const id = router?.query?.id ? router?.query?.id[0] : '0';
  const [activeTab, setActiveTab] = useState(0);
  const [form] = Form.useForm();
  const [maxAccessTab, setMaxAccessTab] = useState(0);

  function keywordsLengthIsNotValid(keywordsValue) {
    const keywordsArray = keywordsValue.split(',');

    const nonEmptyKeywords = keywordsArray
      .map(keyword => keyword.trim())
      .filter(keyword => keyword !== '');

    return nonEmptyKeywords.length > 5;
  }

  const [tldValue, setTldValue] = useState([]);
  const [drFrom, setdrFrom] = useState(null);
  const [drTo, setdrTo] = useState(null);
  const [trafficFrom, settrafficFrom] = useState(null);
  const [trafficTo, settrafficTo] = useState(null);
  const [kcFrom, setkcFrom] = useState(null);
  const [kcTo, setkcTo] = useState(null);
  const [refDoFrom, setrefDoFrom] = useState(null);
  const [refDoTo, setrefDoTo] = useState(null);

  const [sender, setSender] = useState(0);
  const [existingTemplateEditorState, setExistingTemplateEditorState] = useState(EditorState.createEmpty());
  const [existingTemplateId, setExistingTemplateId] = useState(0);
  const [editorStateV2, setEditorStateV2] = useState('');
  const [followUpEmailsArray, setFollowUpEmailsArray] = useState([{
    name: '',
    subject: '',
    body: '',
    send_after_hours: 3,
  }]);
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [condition, setCondition] = useState('3');
  const [followUpEmails, setFollowUpEmails] = useState(false);
  const [type, setType] = useState('keywords');
  const [blockList, setBlockList] = useState('');
  const [followUpErrors, setFollowUpErrors] = useState(false);
  const [noOfFollowUpEmails, setNoOfFollowUpEmails] = useState([1]);

  useEffect(() => {
    if (createModalVisible) {
      getExistingTemplates();
    }
  }, [createModalVisible]);

  const getFilterValue = filterName => {
    const filteredData = initialFiltersList?.filter(item => item?.name == filterName);
    if (filterName == 'checkboxesFilter') {
      const allChecked = filteredData[0]?.value?.length === filteredData[0]?.filterTypes?.length;
      return filteredData && {value: allChecked ? ['all'] : filteredData[0].value};
    } else {
      return filteredData && {from: filteredData[0].from, to: filteredData[0].to};
    }
  };

  const addEmail = () => {
    setFollowUpEmailsArray(prevArr => [...prevArr, {
      name: '',
      subject: '',
      body: '',
      send_after_hours: +condition,
    }]);
  };

  const handleCreateCampaignClick = async () => {
    const followUpEmailsArr = (noOfFollowUpEmails?.length==followUpEmailsArray?.length || !name || !subject || !body || !condition || body=='<p><br></p>') ? followUpEmailsArray : [...followUpEmailsArray, {
      name: name,
      subject: subject,
      body: body,
      send_after_hours: +condition,
    }];

    if (followUpEmails) {
      if ((followUpErrors && (!name || !subject || !condition || +condition==0 || !body || body=='<p><br></p>')) ||
      (followUpEmailsArray?.filter(e => !e?.name).length || followUpEmailsArray?.filter(e => !e?.send_after_hours).length || followUpEmailsArray?.filter(e => e?.send_after_hours==0).length || followUpEmailsArray?.filter(e => !e?.subject).length || followUpEmailsArray?.filter(e => e?.body=='<p><br></p>').length)) {
        setShowErrors(true);
        return;
      }
    }
    const keywordsArray = keywordsValue.split(',');

    const formattedKeywordsList = keywordsArray
      .map(keyword => keyword.trim())
      .filter(keyword => keyword !== '');

    const usedExistingTemplate = convertToHtml(currentEditorState) == convertToHtml(existingTemplateEditorState);

    const checkDataLoaded = async () => {
      if (campaignRepollingLoading == +id) {
        await getCampaigns(true, +id);
      }
    };

    const payload = {
      name: nameValue,
      max_page: maxPage || 1,
      ...(modalSelectedItemV2 && {project_id: modalSelectedItemV2}),
      target_keywords: type=='keywords' ? formattedKeywordsList : [],
      country_code: selectedCountry,
      location_id: 0,
      ...(getFilterValue('checkboxesFilter') && {tlds: getFilterValue('checkboxesFilter').value}),
      ...(getFilterValue('domain-rating') && {ahrefs_dr_min: getFilterValue('domain-rating').from?.toString() || null}),
      ...(getFilterValue('domain-rating') && {ahrefs_dr_max: getFilterValue('domain-rating').to || null}),
      ...(getFilterValue('traffic') && {ahrefs_ot_min: getFilterValue('traffic').from?.toString() || null}),
      ...(getFilterValue('traffic') && {ahrefs_ot_max: getFilterValue('traffic').to || null}),
      ...(getFilterValue('keyword-count') && {ahrefs_ok_min: getFilterValue('keyword-count').from?.toString() || null}),
      ...(getFilterValue('keyword-count') && {ahrefs_ok_max: getFilterValue('keyword-count').to || null}),
      ...(getFilterValue('ref-domains') && {ahrefs_refdomains_min: getFilterValue('ref-domains').from?.toString() || null}),
      ...(getFilterValue('ref-domains') && {ahrefs_refdomains_max: getFilterValue('ref-domains').to || null}),
      linked_email_id: sender,
      ...(!useTemplateChecked && {template: {
        ...(usedExistingTemplate && existingTemplateId && {id: existingTemplateId}),
        name: nameValue,
        subject: subjectValue,
        body: editorStateV2,
      }}),
      followup_emails: followUpEmails ? followUpEmailsArr?.map(item => ({...item, send_after_hours: item?.send_after_hours * 24})) : null,
      domains_blacklist: !blockList?.trim()?.length ? [] : blockList?.split(', '),
      domains: type=='domains' ? formattedKeywordsList : [],
    };
    if (isInOTTO) {
      payload['otto_project_id'] = getOttoV2Project?.id;
    }
    if (isEditModal) {
      const prePayload = {
        domains_blacklist: !blockList?.trim()?.length ? [] : blockList?.split(', '),
      };
      await editCampaign(payload, isInOTTO ? chartData?.id : id);
      await updateCampaignBlockList(isInOTTO ? chartData?.id : id, prePayload);
      await getChartData(Number(isInOTTO ? chartData?.id : id));
      await getCampaigns(true, +id);
      await checkDataLoaded();
      await getCampaignsOpportunity(Number(id));
    } else {
      await postNewCampaign(payload);
      await getCustomerQuota();
      if (isInQuest) {
        openUrl(`/outreach/campaigns`, '_blank');
      }
    }
    closeModal();
  };

  const isPublicPage = !!getSingleUrlParam('public_hash');

  useEffect(() => {
    (isInOTTO && !isPublicPage) && getCampaignsCustomerEmails();
    if (isEditModal) {
      setNameValue(chartData?.name || '');
      setMaxPage(chartData?.maxPage || 1);
      setKeywordsValue(chartData?.targetKeywords?.join(', ') || chartData?.domains?.join(', ') || '');
      setInitialFiltersList([
        {id: 0, name: 'checkboxesFilter', header: 'TLDs', value: chartData?.tlds?.includes('all') ? ['com', 'net', 'org', 'edu', 'gov', 'us'] : chartData?.tlds || [], customCategoryType: 'position', filterTypes: [
          {label: '.com', value: 'com'},
          {label: '.net', value: 'net'},
          {label: '.org', value: 'org'},
          {label: '.edu', value: 'edu'},
          {label: '.gov', value: 'gov'},
          {label: '.us', value: 'us'},
        ], type: undefined, active: !!chartData?.tlds?.length},
        {id: 1, name: 'domain-rating', header: 'Domain Rating', from: chartData?.ahrefsDrMin || 0, to: chartData?.ahrefsDrMax, type: 'range', active: !!(chartData?.ahrefsDrMin?.toString() || chartData?.ahrefsDrMax)},
        {id: 2, name: 'traffic', header: 'Traffic', from: chartData?.ahrefsOtMin || 0, to: chartData?.ahrefsOtMax, type: 'range', active: !!(chartData?.ahrefsOtMin?.toString() || chartData?.ahrefsOtMax)},
        {id: 3, name: 'keyword-count', header: 'Keyword Count', from: chartData?.ahrefsOkMin || 0, to: chartData?.ahrefsOkMax, type: 'range', active: !!(chartData?.ahrefsOkMin?.toString() || chartData?.ahrefsOkMax)},
        {id: 4, name: 'ref-domains', header: 'Ref. Domains', from: chartData?.ahrefsRefdomainsMin || 0, to: chartData?.ahrefsRefdomainsMax, type: 'range', active: !!(chartData?.ahrefsRefdomainsMin?.toString() || chartData?.ahrefsRefdomainsMax)},
      ]);
      setTldValue(chartData?.tlds?.includes('all') ? ['com', 'net', 'org', 'edu', 'gov', 'us'] : chartData?.tlds || ['com', 'net', 'org', 'edu', 'gov', 'us']);
      // if the filter has a max value but no min value, then set the min value to 0
      setdrFrom(chartData?.ahrefsDrMax ? chartData?.ahrefsDrMin || '0' : chartData?.ahrefsDrMin);
      setdrTo(chartData?.ahrefsDrMax);
      // if the filter has a max value but no min value, then set the min value to 0
      settrafficFrom(chartData?.ahrefsOtMax ? chartData?.ahrefsOtMin || '0' : chartData?.ahrefsOtMin);
      settrafficTo(chartData?.ahrefsOtMax);
      // if the filter has a max value but no min value, then set the min value to 0
      setkcFrom(chartData?.ahrefsOkMax ? chartData?.ahrefsOkMin || '0' : chartData?.ahrefsOkMin);
      setkcTo(chartData?.ahrefsOkMax);
      // if the filter has a max value but no min value, then set the min value to 0
      setrefDoFrom(chartData?.ahrefsRefdomainsMax ? chartData?.ahrefsRefdomainsMin || '0' : chartData?.ahrefsRefdomainsMin);
      setrefDoTo(chartData?.ahrefsRefdomainsMax);
      setSubjectValue(chartData?.template?.subject);
      setExistingTemplateId(chartData?.template?.id);
      setSender(chartData?.linkedEmailId || 0);
      setEditorStateV2(chartData?.template?.body || '');
      setModalSelectedItemV2(chartData?.project || null);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setFollowUpEmailsArray(toJS(chartData?.followupEmails)?.map(({sendAfterHours, ...rest}) => ({...rest, send_after_hours: sendAfterHours ? (sendAfterHours / 24) : sendAfterHours ?? condition})));
      setNoOfFollowUpEmails(chartData?.followupEmails?.length ? chartData?.followupEmails?.map((email, index) => index+1) : [1]);
      setFollowUpEmails(chartData?.followupEmails?.length ? true : false);
      setSelectedCountry(chartData?.countryCode || 'US');
      setBlockList(campaignBlocklist?.join(', '));
      setUseTemplateChecked(!chartData?.template);
      setType(chartData?.domains?.length ? 'domains' : 'keywords');
    } else {
      const textValue = globalBlocklist?.join(', ');
      setBlockList(textValue || '');
      if (isInQuest) {
        setNameValue(data?.keyword);
        setKeywordsValue(data?.domains?.join(', '));
        setType('domains');
      }
    }
  }, [createModalVisible, chartData]);

  const checkActiveTabErrors = () => {
    if (activeTab==0) {
      return !tldValue || !nameValue || !keywordsValue || (type == 'keywords' && keywordsLengthIsNotValid(keywordsValue)) || (type === 'domains' && domainIsNotValid(keywordsValue));
    } else {
      return !useTemplateChecked && (!subjectValue || !editorStateV2 || (editorStateV2=='<p><br></p>'));
    }
  };
  const closeModal = () => {
    setShowErrors(false);
    setType('keywords');
    setKeywordSuggestions([]);
    setCurrentEditorState(EditorState.createEmpty());
    setModalSelectedItemV2(null);
    setNameValue('');
    setKeywordsValue('');
    setSeedValue('');
    setSubjectValue('');
    setUseTemplateChecked(false);
    setSelectedCountry(address?.defaultLocation ?? 'US');
    setTldValue(['com', 'net', 'org', 'edu', 'gov', 'us']);
    setdrFrom('');
    setdrTo('');
    settrafficFrom('');
    settrafficTo('');
    setkcFrom('');
    setkcTo('');
    setrefDoFrom('');
    setrefDoTo('');
    setCreateModalVisible(false);
    setInitialFiltersList([
      {id: 0, name: 'checkboxesFilter', header: 'TLDs', value: ['com', 'net', 'org', 'edu', 'gov', 'us'], customCategoryType: 'position', filterTypes: [
        {label: '.com', value: 'com'},
        {label: '.net', value: 'net'},
        {label: '.org', value: 'org'},
        {label: '.edu', value: 'edu'},
        {label: '.gov', value: 'gov'},
        {label: '.us', value: 'us'},
      ], type: undefined, active: false},
      {id: 1, name: 'domain-rating', header: 'Domain Rating', from: 0, to: 0, type: 'range', active: false},
      {id: 2, name: 'traffic', header: 'Traffic', from: 0, to: 0, type: 'range', active: false},
      {id: 3, name: 'keyword-count', header: 'Keyword Count', from: 0, to: 0, type: 'range', active: false},
      {id: 4, name: 'ref-domains', header: 'Ref. Domains', from: 0, to: 0, type: 'range', active: false},
    ]);
    setEditorStateV2('');
    setActiveTab(0);
    setFollowUpEmailsArray([]);
    setSubject('');
    setBody('');
    setCondition('3');
    setName('');
    setFollowUpEmails(false);
    setMaxAccessTab(0);
    setBlockList('');
    setNoOfFollowUpEmails([1]);
    setFollowUpErrors(false);
  };

  const nextStep = async () => {
    const checkErrors = checkActiveTabErrors();
    if (checkErrors) {
      setShowErrors(true);
      return;
    } else {
      setShowErrors(false);
    }
    try {
      setMaxAccessTab(activeTab + 1);
      setActiveTab(activeTab + 1);
    } catch (error) {
      notification.error('Invalid', 'Invalid form field values');
    }
  };
  const onChangeForm = () => maxAccessTab !== activeTab && setMaxAccessTab(activeTab);

  const handleBack= () => {
    setActiveTab(activeTab-1);
    setShowErrors(false);
  };

  function domainIsNotValid(domainsValue) {
    const domainsArray = domainsValue?.split(',')?.map(it => it?.trim())?.filter(val => val);
    const filteredDomains = domainsArray?.filter(domain => {
      return domain?.match(urlDomainRegex) || domain?.match(urlSubDomainRegex) || domain?.match(targetPageRegex);
    });
    return filteredDomains?.length !== domainsArray?.length;
  }

  const steps = [
    <Form
      key='step1'
      layout='vertical'
      autoComplete='off'
      name='step1'
      onFinish={nextStep}
      form={form}
      onChange={onChangeForm}
    >
      <SectionsWrapper>
        <Description style={{marginBottom: 18}}>Based on the selected keywords and filters, you will get a list of opportunities that can be emailed individually or with a scheduled outreach task</Description>
        <ProjectSection
          nameValue={nameValue}
          setNameValue={setNameValue}
          modalSelectedItemV2={modalSelectedItemV2}
          setModalSelectedItemV2={setModalSelectedItemV2}/>
        <KeywordsSection
          keywordsLengthIsNotValid={keywordsLengthIsNotValid}
          domainIsNotValid={domainIsNotValid}
          keywordsValue={keywordsValue}
          setKeywordsValue={setKeywordsValue}
          type={type}
          setType={setType}
          seedValue={seedValue}
          setSeedValue={setSeedValue}
          isEdit={isEditModal}
          setMaxPage={setMaxPage}
          maxPage={maxPage}
        />
        <FiltersSection
          initialFiltersList={initialFiltersList}
          setInitialFiltersList={setInitialFiltersList}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          tldValue={tldValue}
          setTldValue={setTldValue}
          drFrom={drFrom}
          setdrFrom={setdrFrom}
          drTo={drTo}
          setdrTo={setdrTo}
          trafficFrom={trafficFrom}
          settrafficFrom={settrafficFrom}
          trafficTo={trafficTo}
          settrafficTo={settrafficTo}
          kcFrom={kcFrom}
          setkcFrom={setkcFrom}
          kcTo={kcTo}
          setkcTo={setkcTo}
          refDoFrom={refDoFrom}
          setrefDoFrom={setrefDoFrom}
          refDoTo={refDoTo}
          setrefDoTo={setrefDoTo}
          showWorldWide={false}
        />
        <BlockListSection blockList={blockList} setBlockList={setBlockList} />
      </SectionsWrapper>
    </Form>,
    <Form
      key='step2'
      layout='vertical'
      autoComplete='off'
      name='step2'
      onFinish={nextStep}
      onChange={onChangeForm}
    >
      <EmailPresetSection
        subjectValue={subjectValue}
        setSubjectValue={setSubjectValue}
        useTemplateChecked={useTemplateChecked}
        setUseTemplateChecked={setUseTemplateChecked}
        setSender={setSender}
        setExistingTemplateEditorState={setExistingTemplateEditorState}
        setExistingTemplateId={setExistingTemplateId}
        editorState={editorStateV2}
        onEditorV2Change={setEditorStateV2}
        sender={sender}
      />
    </Form>,
    <Form
      key='step3'
      layout='vertical'
      autoComplete='off'
      name='step3'
      onChange={onChangeForm}
    >

      <FollowUpEmailsSection
        subjectValue={subjectValue}
        setSubjectValue={setSubjectValue}
        setSender={setSender}
        setExistingTemplateEditorState={setExistingTemplateEditorState}
        setExistingTemplateId={setExistingTemplateId}
        editorState={editorStateV2}
        onEditorV2Change={setEditorStateV2}
        setFollowUpEmailsArray={setFollowUpEmailsArray}
        followUpEmailsArray={followUpEmailsArray}
        condition={condition}
        setCondition={setCondition}
        body={body}
        setBody={setBody}
        subject={subject}
        setSubject={setSubject}
        addEmail={addEmail}
        nameVal={name}
        setName={setName}
        followUpEmails={followUpEmails}
        setFollowUpEmails={setFollowUpEmails}
        setFollowUpErrors={setFollowUpErrors}
        noOfFollowUpEmails={noOfFollowUpEmails}
        setNoOfFollowUpEmails={setNoOfFollowUpEmails}
        isEdit={isEditModal}
        sender={sender}
      />
    </Form>,
  ];

  return <StyledModal
    visible={createModalVisible}
    destroyOnClose={true}
    closable={false}
    isLast={activeTab === tabsTitle.length - 1}
    width={735}
    mask={true}
    style={{margin: '20px 0 0 0'}}
    onClose={() => closeModal()}
    footer={false}
    zIndex={20}>
    <div className='header'>
      <Heading>{isEditModal ? 'Edit Campaign' : 'Create Campaign'}</Heading>
      <span onClick={() => closeModal()}>
        <FontAwesomeIcon className='close-icon' icon={faXmark} />
      </span>
    </div>
    <div className='steps-wrapper'>
      <div className='steps'>
        {tabsTitle.map((_, index) => (
          <div
            key={index}
            className={`section ${
              index <= activeTab ? 'active-section ' : ''} ${
              index <= maxAccessTab ? '' : ' restricted'
            }`}
            onClick={() => index <= maxAccessTab && setActiveTab(index)}
          >
            <div className='step'>{`Step ${index + 1}`}</div>
            <div className={`step ${index <= activeTab ? 'active' : ''}`}>
              {tabsTitle[index]}
            </div>
          </div>
        ))}
      </div>
      <div className='border-line' />
    </div>
    <div className={`checkout-scroll`}>{steps[activeTab]}</div>
    <Divider />
    <ButtonsWrapper key='wrapper'>
      <ButtonStyled isCancel={true} onClick={activeTab == 0 ? closeModal : handleBack}>
        {activeTab != 0 ? 'Back' : 'Cancel'}
      </ButtonStyled>

      <ButtonStyled type='primary' onClick={activeTab == 2 ? handleCreateCampaignClick : nextStep} loading={postNewCampaignLoading}>
        {activeTab != 2 ? 'Next Step' : isEditModal ? 'Update Campaign' : 'Create Campaign'}
        <FontAwesomeIcon icon={faArrowRight} style={{marginTop: '2px'}}/>
      </ButtonStyled>
    </ButtonsWrapper>
  </StyledModal>;
});

const StyledModal = styled(ModalStyled)<{isLast: boolean}>`
  max-width: 735px;
  width: 100%;
  padding-bottom: 0;
  min-height: ${p=> p.isLast ? '400px' : '680px'};
  border-radius: 16px !important;

  .rc-dialog-content {
    height: 100%;
    min-height: 100%;
    width: 100%;
    padding: 0;
  }

  .checkout-scroll {
    margin-bottom: 12px;
    ${p => p.isLast ? `
      @media screen and (max-width: 650px) {
      max-height: calc(100vh - 200px);
      overflow-y: scroll;
      overflow-x: hidden;
      min-height: 400px;
    }
    ` : ''}
  }
  .rc-dialog-wrap {
    display: flex !important;
    justify-content: center !important;
  }
  .rc-dialog-body {
    padding: 30px 30px 20px 30px !important;
    border-radius: 16px !important;
    background: #FBFBFB !important;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;

    span {
      cursor: pointer;
    }
  }

  .steps-wrapper {
    position: relative;

    .steps {
      display: flex;
    }

    .border-line {
      bottom: 3px;
      position: relative;
      width: 100%;
      height: 2px;
      border-bottom: 2px solid #e8e8e8 !important;
    }
    .section {
      flex-grow: 1;
      cursor: pointer;

      &.active-section {
        z-index: 1;
        padding-bottom: 8px;
        border-bottom: 4px solid rgba(45, 108, 202, 1);
      }
      &.restricted {
        cursor: default;
      }
      .step {
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        color: #4e5156;

        &.active {
          font-weight: 500;
          color: #121212;
        }
      }
    }
  }

  .close-icon {
    color: #a3a4a4;
    font-size: 28px;
  }
`;
