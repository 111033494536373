import NextApp, {AppProps} from 'next/app';
import Head from 'next/head';
import {appWithTranslation} from 'next-i18next';
import React, {useEffect, useState} from 'react';
import Script from 'next/script';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react-lite';
import {initSentry} from '@/utils/sentry';
import {Drawer, Row, Col} from 'antd';

import Layout from '@/components/layoutV2';
import {SettingsPage} from '@/components/dashboard/settings';
import {useTranslation} from 'next-i18next';
import {PricingSidebar} from '@/components/dashboard/billing/pricing-sidebar';
import {OutDomainsDrawer} from '@/components/dashboard/backlinks/out_domains';
import {
  getSingleUrlParam,
  isKrPublicPage,
  isCaPublicPage,
  getDomain,
  copyAndModifyQueryParams,
} from '@/utils/url';
import {useRouter} from 'next/router';

import 'antd/dist/antd.css';
import './app.scss';

// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import '@fortawesome/fontawesome-svg-core/styles.css';
// Prevent fontawesome from adding its CSS since we did it manually above:
import {config} from '@fortawesome/fontawesome-svg-core';

import {MixPanel} from '@/utils/mixpanel';
import {DASHBOARD_PAGE_IS_OPENED} from '@/constants/events';
import {CloseOutlined} from '@ant-design/icons';
import {linkgraphDomain, publicPages} from '@/utils/const';
import {MeetingModal} from '@/components/dashboard/pages/competitor-researcher/domain-analyzer-public/meeting-modal';
import {getApiUrl, getTokenFromCookies} from '@/api/common-utils';
import {CustomerSwitcherModal} from '@/components/layoutV2/user-menu/customer-switcher/customerSwitcher';
import {BaseApi} from '@/api/base-api';
import axios from 'axios';
import {profileApi} from '@/api/account';
import {linkgraphDomains} from '@/utils/router';
import SettingsModal from '@/components/common-components/components/settings-modal';
// import crypto from 'crypto';
import OnboardingPromotionModal from '@/components/layoutV2/user-menu/onboarding-promotion-modal';

config.autoAddCss = false;

initSentry();

type StaticComponents = {
  getInitialProps?: typeof NextApp.getInitialProps;
};

declare global {
  interface Window {
    Intercom: (...args: any[]) => void;
  }
}

// disable warnings and logs on production
if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line no-console
  // console.log = console.warn = () => {};
}

const App: React.FC<AppProps> & StaticComponents = observer(
  ({Component, pageProps}: AppProps) => {
    const router = useRouter();
    const store = useStore(pageProps.initialState);
    const {
      countries: {getCountriesData, loadCountriesData},
      gsc: {
        isDrawerVisible,
        setDrawerVisibility,
        setIsTeamMember,
        isSettingModalVisible,
        setSettingModalVisible,
      },
    } = useStore('');
    const {
      home: {setIsChildAccount, isChild},
    } = useStore('');
    const {
      plans: {getSidebarPayment, handlePayAnnual},
      settings: {
        customer: {
          getExternalSettings,
          getCustomersPlanOptions,
          setOttoLabel,
          profile,
          profile: {
            hasOnboardingCompleted,
            getVendastaClientDetails,
            setLogoInStore,
            logo,
            setSignInLogoInStore,
            setSignUpLogoInStore,
            setBrandColor,
            setDomainApiLoading,
            joiningDate,
          },
        },
      },
    } = useStore('');
    const {
      siteAuditorV2: {
        setSelectedSiteAuditInStore,
        loadAuditedSitesListBasic,
        getAuditSitesList,
        getAuditSitesListBasic,
        siteAuditSelectedProperty,
      },
    } = useStore('');
    const {
      competitorResearcherV2: {
        overview: {outDomainsVisible},
      },
    } = useStore('');
    const {t} = useTranslation('common');
    const today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth();
    const yy = today.getFullYear();
    const token = getTokenFromCookies();
    const [showCustomerModal, setShowCustomerModal] = useState(false);
    const [showOnboardingPromotionModal, setShowOnboardingPromotionModal] = useState(false);
    const [customerId, setCustomerId] = useState<any>();
    const [customerToken, setCustomerToken] = useState<any>();
    const currentCustomerId =
      typeof window !== 'undefined' &&
      localStorage.getItem('currentCustomerId');
    const [favicon, setFavicon] = useState(null);

    const storedDate =
      typeof window !== 'undefined' && Number(localStorage.getItem('date'));
    if (!storedDate && token && !profile.isWhitelabel) {
      localStorage.setItem('compDate', `${mm}/${dd}/${yy}`);
      typeof window !== 'undefined' && localStorage.setItem('date', String(dd));
    }

    const getDiffDays = (firstDate, secondDate) => {
      const date1 = new Date(firstDate) as any;
      const date2 = new Date(secondDate) as any;
      date1.setHours(0, 0, 0, 0);
      date2.setHours(0, 0, 0, 0);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      return Math.ceil(diffDays);
    };

    useEffect(() => {
      getExternalSettings();
      if (storedDate && storedDate !== dd && token && !profile.isWhitelabel) {
        const comparisonDate =
          typeof window !== 'undefined' &&
          (localStorage.getItem('compDate') as any);
        const currentData = `${mm}/${dd}/${yy}` as any;
        const date1 = new Date(comparisonDate) as any;
        const date2 = new Date(currentData) as any;
        const getDiff = Math.abs(date2 - date1);
        const diffDays = Math.ceil(getDiff / (1000 * 60 * 60 * 24));

        if (diffDays >= 14) {
          localStorage.setItem('compDate', `${mm}/${dd}/${yy}`);
          localStorage.setItem('showConsultationModal', 'true');
        }
        typeof window !== 'undefined' &&
          localStorage.setItem('date', String(dd));
      }
    }, []);

    useEffect(() => {
      if (joiningDate && !profile.isWhitelabel) {
        const creationDate = joiningDate;
        const currentDate = `${mm + 1}/${dd}/${yy}`;
        const alreadyBooked = JSON.parse(localStorage.getItem('onboardingCallBooked'));
        if (!alreadyBooked) {
          if (getDiffDays(currentDate, creationDate) <= 14) {
            const previousShownDate = localStorage.getItem('callModalShownDate');
            if (!previousShownDate || (previousShownDate && getDiffDays(currentDate, previousShownDate) >= 1)) {
            // show modal
              setShowOnboardingPromotionModal(true);
            }
          }
        }
      }
    }, [joiningDate]);

    const showCrisp = [
      `dashboard.${linkgraphDomain}`,
      'dashboard.searchatlas.com',
    ];
    const showCrispOnAgency = ['my.agencyremix.app'];
    const getHostName = typeof window !== 'undefined' && location.hostname;
    const showCrispOnBrewroom = ['dashboard.thebrewroom.com'];

    useEffect(() => {
      const GSCIntegration = getSingleUrlParam('active_tab');
      if (GSCIntegration) {
        setDrawerVisibility(true);
      }

      const publicHash = getSingleUrlParam('public_hash');

      if (!publicHash) {
        if (
          showCrisp.includes(getHostName) ||
          showCrispOnAgency.includes(getHostName) ||
          showCrispOnBrewroom.includes(getHostName)
        ) {
          // Initializing window.dataLayer array on app mount
          window['dataLayer'] = window['dataLayer'] || [];
        }
      }
    }, []);
    useEffect(() => {
      const promoCode = getSingleUrlParam('promo_code');
      if (promoCode) {
        localStorage.setItem('promotionCode', promoCode);
      }
      const checkPayAnnual = getSingleUrlParam('pay_annually');
      if (checkPayAnnual) {
        handlePayAnnual(true);
      }
      if (!getCountriesData?.length) {
        loadCountriesData();
      }
    }, [router]);

    useEffect(() => {
      window['ffWidgetId'] = '';
      const ffWidgetScript = document.createElement('script');
      ffWidgetScript.type = 'text/javascript';
      ffWidgetScript.src =
        'https://freddyfeedback.com/widget/freddyfeedback.js';
      window['ffWidgetScript'] = ffWidgetScript;
      document.head.appendChild(ffWidgetScript);
    }, []);

    useEffect(() => {
      if (router?.asPath?.includes('customerSwitcherModal')) {
        setShowCustomerModal(true);
        getCustomersPlanOptions();
      }
    }, []);

    const getPageUrlForMixpanel = (page: string) => {
      if (page === '/gsc/overview' || page === '/gsc') {
        return '/gsc/overview';
      } else if (page.startsWith('/research/keyword-researcher-old/detail/')) {
        return '/research/keyword-researcher-old/detail/';
      } else if (page.startsWith('/content/seo-content-assistant/')) {
        return '/content/seo-content-assistant/[articleID]';
      } else if (page.startsWith('/research/keyword-researcher-old/list/')) {
        return '/research/keyword-researcher-old/list/';
      } else {
        return page;
      }
    };

    const getListAndUpdateSA = async () => {
      if (getAuditSitesList?.length) {
        setSelectedSiteAuditInStore(getAuditSitesList[0]?.id);
      } else if (getAuditSitesListBasic?.length) {
        setSelectedSiteAuditInStore(getAuditSitesListBasic[0]?.id);
      } else {
        await loadAuditedSitesListBasic();
        if (getAuditSitesListBasic?.length) {
          setSelectedSiteAuditInStore(getAuditSitesListBasic[0]?.id);
        }
      }
    };

    useEffect(() => {
      const {searchParams} = new URL(window.location.href);
      const customer = searchParams.getAll('customerId');
      if (!customerToken && customer.length) {
        setCustomerId(customer[0]);
        getCustomerJwt(Number(customer[0]) || Number(customerId));
      }
      if (customerToken) {
        if (profile?.isParentAccount == false) {
          if (!siteAuditSelectedProperty) {
            getListAndUpdateSA();
          }
          typeof window !== 'undefined' &&
            localStorage.setItem('currentCustomerId', customerId);
          router.query.customerId =
            customer[0] || customerId || currentCustomerId;
          router.push(router);
          setIsChildAccount(true);
        } else {
          setIsChildAccount(false);
        }
      }
    }, [router.asPath]);

    const getCustomerJwt = async (id: number) => {
      if (!isNaN(id)) {
        const headers = {};
        headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        try {
          // eslint-disable-next-line no-undef
          const baseUrl = getApiUrl(
            BaseApi.LINKGRAPH_ENDPOINT,
            '/api/customer',
          );
          // eslint-disable-next-line no-invalid-this
          const response = await axios.get(`${baseUrl}/child-accounts/${id}/`, {
            headers: headers,
            // eslint-disable-next-line no-invalid-this
          });
          setCustomerToken(response?.data?.token);
          return response.data;
        } catch (e) {
          return Promise.reject(e);
        }
      }
    };

    useEffect(() => {
      if (!router.asPath.includes('site-audit/page-explorer')) {
        localStorage.removeItem('segmentData');
      }
    }, [router.pathname]);

    useEffect(() => {
      if (router.isReady && router?.pathname != '/login') {
        MixPanel.track(DASHBOARD_PAGE_IS_OPENED, {
          page: getPageUrlForMixpanel(window.location.pathname),
        });
      }
    }, [router.pathname, router.isReady]);

    const getLogo = async () => {
      try {
        setDomainApiLoading(true);
        const logos = await profileApi.getDomainLogos();
        if (logos) {
          if (logos?.faviconUrl) {
            setFavicon(logos?.faviconUrl);
          } else {
            setFavicon('/img/official-favicon-new.png');
          }
          setLogoInStore(`${logos?.logoUrl}`);
          setSignInLogoInStore(`${logos?.signupLogoUrl || logos?.logoUrl}`);
          setSignUpLogoInStore(`${logos?.signupLogoUrl || logos?.logoUrl}`);
          setBrandColor(logos?.brandColor);
          setOttoLabel(logos?.whitelabelOtto);
        }
        setDomainApiLoading(false);
      } catch (e) {
        // eslint-disable-next-line
        console.warn("Error fetching logos", e);
      }
    };
    useEffect(() => {
      const contentAssistantPublicPage =
        '/content/seo-content-assistant-public/';
      if (
        !publicPages.includes(location.pathname) &&
        router.asPath !== '/onboarding' &&
        router.asPath !== '/' &&
        !isKrPublicPage() &&
        !isCaPublicPage() &&
        !window.location.pathname.match(contentAssistantPublicPage) &&
        !router.asPath.includes('jwtToken')
      ) {
        localStorage.setItem('nextUrl', router.asPath);
      }
      if (!logo) {
        getLogo();
      }
    }, [router.pathname]);

    const getVandastaNavBar = data => {
      const dataScript = (
        <Script
          src='//www.cdnstyles.com/static/product_navbar/v1/product_navbar.js'
          data-url={`${data?.dataUrl}`}
          data-account-id={`${data?.dataAccountId}`}
          data-app-id={`${data?.dataAppId}`}
        />
      );
      return dataScript;
    };

    const canonicalURL = `https://dashboard.linkgraph.com${
      router.asPath.split('?')[0]
    }`;
    const formatAccountID = (accountID: number) => {
      const accountIDString = accountID?.toString();
      const formattedID = accountIDString.replace(/(\d{3})(?=\d)/g, '$1-');
      return formattedID;
    };

    useEffect(() => {
      if (
        getSingleUrlParam('google-search-console') ||
        getSingleUrlParam('google-analytics') ||
        getSingleUrlParam('google-business')
      ) {
        setDrawerVisibility(true);
      }
    }, []);

    const [currentDomain, setCurrentDomain] = useState('');

    useEffect(() => {
      setCurrentDomain(getDomain(window.location.hostname));
    }, []);

    const isWhiteLabelDomain = !linkgraphDomains.includes(currentDomain);
    const showConsultationModal =
      typeof window !== 'undefined' &&
      localStorage.getItem('showConsultationModal') == 'true' &&
      getDiffDays(`${mm + 1}/${dd}/${yy}`, joiningDate) > 14 &&
      !profile.isWhitelabel;

    const vwoScript = `
    window._vwo_code || (function() {
        var account_id=958458,
        version=2.1,
        settings_tolerance=2000,
        hide_element='body',
        hide_element_style = 'opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;transition:none !important;',
        f=false,w=window,d=document,v=d.querySelector('#vwoCode'),cK='_vwo_'+account_id+'_settings',cc={};
        try {
            var c=JSON.parse(localStorage.getItem('_vwo_'+account_id+'_config'));
            cc=c && typeof c==='object' ? c : {};
        } catch(e) {}
        var stT = cc.stT === 'session' ? w.sessionStorage : w.localStorage;

        code = {
            nonce: v && v.nonce,
            use_existing_jquery: function() {
                return typeof use_existing_jquery !== 'undefined' ? use_existing_jquery : undefined;
            },
            library_tolerance: function() {
                return typeof library_tolerance !== 'undefined' ? library_tolerance : undefined;
            },
            settings_tolerance: function() {
                return cc.sT || settings_tolerance;
            },
            hide_element_style: function() {
                return '{' + (cc.hES || hide_element_style) + '}';
            },
            hide_element: function() {
                if (performance.getEntriesByName('first-contentful-paint')[0]) {
                    return '';
                }
                return typeof cc.hE === 'string' ? cc.hE : hide_element;
            },
            getVersion: function() {
                return version;
            },
            finish: function(e) {
                if (!f) {
                    f = true;
                    var t = d.getElementById('_vis_opt_path_hides');
                    if (t) t.parentNode.removeChild(t);
                    if (e) (new Image).src = 'https://dev.visualwebsiteoptimizer.com/ee.gif?a=' + account_id + e;
                }
            },
            finished: function() {
                return f;
            },
            addScript: function(e) {
                var t = d.createElement('script');
                t.type = 'text/javascript';
                if (e.src) {
                    t.src = e.src;
                } else {
                    t.text = e.text;
                }
                v && t.setAttribute('nonce', v.nonce);
                d.getElementsByTagName('head')[0].appendChild(t);
            },
            load: function(e, t) {
                var n = this.getSettings(),
                    i = d.createElement('script'),
                    r = this;
                t = t || {};
                if (n) {
                    i.textContent = n;
                    d.getElementsByTagName('head')[0].appendChild(i);
                    if (!w.VWO || VWO.caE) {
                        stT.removeItem(cK);
                        r.load(e);
                    }
                } else {
                    var o = new XMLHttpRequest();
                    o.open('GET', e, true);
                    o.withCredentials = !t.dSC;
                    o.responseType = t.responseType || 'text';
                    o.onload = function() {
                        if (t.onloadCb) {
                            return t.onloadCb(o, e);
                        }
                        if (o.status === 200 || o.status === 304) {
                            _vwo_code.addScript({ text: o.responseText });
                        } else {
                            _vwo_code.finish('&e=loading_failure:' + e);
                        }
                    };
                    o.onerror = function() {
                        if (t.onerrorCb) {
                            return t.onerrorCb(e);
                        }
                        _vwo_code.finish('&e=loading_failure:' + e);
                    };
                    o.send();
                }
            },
            getSettings: function() {
                try {
                    var e = stT.getItem(cK);
                    if (!e) {
                        return;
                    }
                    e = JSON.parse(e);
                    if (Date.now() > e.e) {
                        stT.removeItem(cK);
                        return;
                    }
                    return e.s;
                } catch(e) {
                    return;
                }
            },
            init: function() {
                if (d.URL.indexOf('__vwo_disable__') > -1) return;
                var e = this.settings_tolerance();
                w._vwo_settings_timer = setTimeout(function() {
                    _vwo_code.finish();
                    stT.removeItem(cK);
                }, e);
                var t;
                if (this.hide_element() !== 'body') {
                    t = d.createElement('style');
                    var n = this.hide_element(),
                        i = n ? n + this.hide_element_style() : '',
                        r = d.getElementsByTagName('head')[0];
                    t.setAttribute('id', '_vis_opt_path_hides');
                    v && t.setAttribute('nonce', v.nonce);
                    t.setAttribute('type', 'text/css');
                    if (t.styleSheet) {
                        t.styleSheet.cssText = i;
                    } else {
                        t.appendChild(d.createTextNode(i));
                    }
                    r.appendChild(t);
                } else {
                    t = d.getElementsByTagName('head')[0];
                    var i = d.createElement('div');
                    i.style.cssText = 'z-index: 2147483647 !important;position: fixed !important;left: 0 !important;top: 0 !important;width: 100% !important;height: 100% !important;background: white !important;';
                    i.setAttribute('id', '_vis_opt_path_hides');
                    i.classList.add('_vis_hide_layer');
                    t.parentNode.insertBefore(i, t.nextSibling);
                }
                var o = window._vis_opt_url || d.URL,
                    s = 'https://dev.visualwebsiteoptimizer.com/j.php?a=' + account_id + '&u=' + encodeURIComponent(o) + '&vn=' + version;
                if (w.location.search.indexOf('_vwo_xhr') !== -1) {
                    this.addScript({ src: s });
                } else {
                    this.load(s + '&x=true');
                }
            }
        };
        w._vwo_code = code;
        code.init();
    })();
`;

    // const hashEmail = email => {
    //   return crypto.createHash('sha256').update(email).digest('hex');
    // };

    // useEffect(() => {
    //   if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
    //     if (window['clarity'] && profile?.emailAddress && token) {
    //       window['clarity']('consent');
    //       const res = window['clarity']('identify', hashEmail(profile?.emailAddress));
    //       window['clarity']('set', 'userId', res?.id);
    //     }
    //   }
    // }, [profile]);

    return (
      <>
        <Head>
          <link rel='icon' href={isWhiteLabelDomain ? favicon : '/img/official-favicon-new.png'} sizes='32x32' />
          {getHostName && (<title>{getHostName?.replace('dashboard.', '')}</title>)}
          {(showCrisp.includes(getHostName)) ? <>
            <link rel='preconnect' href='https://dev.visualwebsiteoptimizer.com' />
            <script type='text/javascript' id='vwoCode' dangerouslySetInnerHTML={{__html: vwoScript}}></script>
            <script dangerouslySetInnerHTML={{__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WGBMQTD');
          `}}/>
            <script src={`/scripts/fpr_main.js`} async></script>
            <script
              src={`https://cdn.firstpromoter.com/fpr.js`}
              async
            ></script>
            <link rel='canonical' href={canonicalURL} key='canonical' />

            {/* Meta Pixel Code */}
            <script
              dangerouslySetInnerHTML={{
                __html: `!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1529626644659448');
            fbq('track', 'PageView');`,
              }}
            ></script>
            <noscript>
              <img
                height='1'
                width='1'
                style={{display: 'none'}}
                src='https://www.facebook.com/tr?id=1529626644659448&ev=PageView&noscript=1'
              />
            </noscript>
            {/* End Meta Pixel Code */}

            <script
              dangerouslySetInnerHTML={{
                __html: `
          var head = document.head;
          var script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = "https://206750.t.hyros.com/v1/lst/universal-script?ph=813c7c59b8a9c1979aedf376625d03e7ee0f0c1d1a54b87ddde8c0d4c76f2261&tag=!clicked&ref_url=" + encodeURI(document.URL) ;
          head.appendChild(script);`,
              }}
            ></script>

            <Script
              id='dd-rum-sync'
              src='https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js'
              type='text/javascript'
              strategy='beforeInteractive'
            />
            <Script id='datadog-rum'>
              {`
            window.DD_RUM && window.DD_RUM.init({
              clientToken: '${process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN}',
              applicationId: '${process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID}',
              site: 'datadoghq.com',
              service: '${process.env.NEXT_PUBLIC_DATADOG_SERVICE}',
              env: '${process.env.NEXT_PUBLIC_DATADOG_ENV}',
              sessionSampleRate: ${process.env.NEXT_PUBLIC_DATADOG_SESSION_SAMPLE_RATE},
              sessionReplaySampleRate: ${process.env.NEXT_PUBLIC_DATADOG_SESSION_REPLAY_SAMPLE_RATE},
              trackUserInteractions: true,
              trackResources: true,
              trackLongTasks: true,
              defaultPrivacyLevel: 'mask-user-input',
            });
          `}
            </Script>
          </> : (
            ''
          )}
          {process.env.NODE_ENV === 'production' && <script type='text/javascript' dangerouslySetInnerHTML={{
            __html: `(function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "p5lqqqvxas");`}} />}
        </Head>
        <Layout store={store} pathname={pageProps.pathname}>
          <Component {...pageProps} rootStore={store} />
          <Row gutter={20}>
            <Col
              span={24}
              style={!isDrawerVisible && {paddingLeft: 0, minHeight: 0}}
            >
              <Drawer
                width={'88%'}
                title={
                  <div className='modal-header'>
                    {t('settings')}
                    <div className={'account-id-wrapper'}>
                      Account ID:{' '}
                      {profile?.customerId ?
                        formatAccountID(profile.customerId) :
                        '-'}
                    </div>
                  </div>
                }
                visible={isDrawerVisible}
                placement='right'
                contentWrapperStyle={{maxWidth: '900px'}}
                destroyOnClose={true}
                closable={true}
                onClose={() => {
                  setIsTeamMember(false);
                  setDrawerVisibility(false);
                  const queryParam =
                    isChild && currentCustomerId ?
                      [{key: 'customerId', value: currentCustomerId}] :
                      [{key: '', value: ''}];
                  router.push(copyAndModifyQueryParams(queryParam), undefined, {
                    shallow: true,
                  });
                }}
                closeIcon={
                  <CloseOutlined
                    className={'closeIcon'}
                    style={
                      isDrawerVisible ?
                        {display: 'block'} :
                        {display: 'none'}
                    }
                  />
                }
                headerStyle={{
                  background: '#F2F2F2',
                  fontSize: '24px',
                  paddingLeft: '30px',
                }}
                className={'settingsDrawer drawerCloseIcon'}
                bodyStyle={{
                  backgroundColor: '#F2F2F5',
                  padding: '0 30px',
                }}
              >
                <SettingsPage store={store} />
              </Drawer>
              {getSidebarPayment && <PricingSidebar />}
              {outDomainsVisible ? <OutDomainsDrawer /> : ''}
            </Col>
          </Row>
          {/* Google Tag Manager (noscript) */}
          {showCrisp.includes(getHostName) ? (
            <noscript>
              <iframe
                src='https://www.googletagmanager.com/ns.html?id=GTM-WGBMQTD'
                height='0'
                width='0'
                style={{display: 'none', visibility: 'hidden'}}
              ></iframe>
            </noscript>
          ) : (
            ''
          )}
          {/* End Google Tag Manager (noscript) */}
        </Layout>
        {hasOnboardingCompleted == true && showConsultationModal && (
          <MeetingModal />
        )}
        {showCustomerModal && (
          <CustomerSwitcherModal
            setShowCustomerModal={setShowCustomerModal}
            showCustomerModal={showCustomerModal}
          />
        )}
        {(hasOnboardingCompleted == true && showOnboardingPromotionModal) && (
          <OnboardingPromotionModal
            visible={showOnboardingPromotionModal}
            setVisible={setShowOnboardingPromotionModal}
          />
        )}
        {getVendastaClientDetails?.isVendastaClient ?
          getVandastaNavBar(getVendastaClientDetails) :
          ''}
        {customerToken && (
          <input
            value={customerToken}
            id='customerToken'
            style={{display: 'none'}}
            className='customerToken'
          ></input>
        )}
        {isSettingModalVisible && (
          <SettingsModal
            visible={isSettingModalVisible}
            onClose={() => setSettingModalVisible(false)}
          />
        )}
      </>
    );
  },
);

App.getInitialProps = async appContext => {
  if (appContext.ctx.asPath === '/readiness') {
    appContext.ctx.res.writeHead(200);
    appContext.ctx.res.end();
    return;
  }

  return await NextApp.getInitialProps(appContext);
};

export default appWithTranslation(App);
