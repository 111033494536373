import React, {useEffect, useRef, useState, Fragment} from 'react';
import {observer} from 'mobx-react-lite';
import {Form, Row, Col, Select, Tooltip} from 'antd';
import {useStore} from '@/store/root-store';
import styles from '@/components/dashboard/settings/styles.module.scss';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  CommonInput,
} from '@/components/common-components/components/index';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';
import {notification} from '@/utils/notifications';

import ButtonComponent from '@/components/common-components/components/button-component';

import {MixPanel} from '@/utils/mixpanel';
import {SETTINGS_ACCOUNT_DETAILS_UPDATE} from '@/constants/events';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {useRouter} from 'next/router';
import {faCopy} from '@fortawesome/pro-light-svg-icons';
import Flag from '@/components/common-components/components/flag';
import {CaretDownOutlined} from '@ant-design/icons';
import {countryCodeToLocationDict} from '@/utils/const';
import {faCircleInfo} from '@fortawesome/pro-duotone-svg-icons';

type FormFieldsProps = {
  phoneNumber: string;
  contactName: string;
  industry: string;
  defaultLocation: string;
};

export const UpdateProfileSectionV2: React.FC = observer(() => {
  const {settings} = useStore('');
  const {customer} = settings;
  // const router = useRouter();
  const {t} = useTranslation('common');
  const modalContentRef = useRef(null);

  const {loadProfile, loading, profile, updateProfile} = customer;
  const {settings: {customer: {profile: {referralLink, isWhitelabel}}}} = useStore('');
  // const customerId = router.asPath.includes('?customerId=');
  // const getProjectName = (property: string) => {
  //   return property ? (property.startsWith('sc-domain:') ? property.split('sc-domain:')[1] : property) : '';
  // };
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [fullNameValue, setFullNameValue] = useState('');
  const [industryValue, setIndustryValue] = useState('');
  const [phoneNumberValue, setPhoneNumberValue] = useState('');


  // const data = navBar.properties.map(sp => ({
  //   name: getProjectName(sp.url),
  //   value: sp.url,
  // }));

  useEffect(() => {
    loadProfile();
  }, [loadProfile]);

  useEffect(() => {
    const handleScroll = () => {
      setIsDropdownVisible(false); // Hide dropdown when modal content is scrolled
      setTimeout(() => {
        setIsDropdownVisible(true); // Re-open dropdown after slight delay
      }, 0); // Small delay to allow for the dropdown to re-render
    };
    // Attach scroll event listener to the modal content
    const modalContent = modalContentRef.current;
    if (modalContent) {
      modalContent.addEventListener('scroll', handleScroll);
    }

    // Cleanup the scroll event listener on unmount
    return () => {
      if (modalContent) {
        modalContent.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const onProfileUpdate = async ({phoneNumber, contactName, industry, defaultLocation}: FormFieldsProps) => {
    await updateProfile({
      phoneNumber,
      contactName,
      industry,
      defaultLocation,
      hideNotification: true,
    });
    notification.success(t('profile-updated-successfully'));
    loadProfile();
    const details = {
      phone: phoneNumber,
      name: contactName,
      industry: industry,
    };

    MixPanel.track(SETTINGS_ACCOUNT_DETAILS_UPDATE, {
      'onAccountDetailsUpdate': `${details}`,
    });
  };

  // const formatPhone = (phone: string) => {
  //   phone = phone.replace(/\D+/g, '');
  //   if (phone.length > 3 && phone.length <= 6) {
  //     return `${phone.slice(0, 3)}-${phone.slice(3, 6)}`;
  //   }
  //   if (phone.length > 6) {
  //     return `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(
  //       6,
  //       phone.length,
  //     )}`;
  //   }
  //   return phone;
  // };

  const copyLink = () => {
    const str = 'https://affiliates.searchatlas.com/';
    navigator.clipboard.writeText(str).then(function() {
      notification.success('Copied!');
    }, function(err) {
      notification.error('Error', err);
    });
  };

  const {Option} = Select;

  const initialValues = {
    contactName: profile?.contactName,
    industry: profile?.industry,
    emailAddress: profile?.emailAddress,
    phoneNumber: profile?.phoneNumber,
    defaultLocation: profile?.address?.defaultLocation,
  };
  // const avatarName = profile.fullName.length ? profile.fullName.split(' ').reduce((acc, n)=>acc+n[0], '').toUpperCase() : 'LG';
  return (
    <Fragment>
      <div className={styles.content} style={{padding: 25}}>
        <div className={styles.title}>
          {'Account details'}
        </div>
        <Form onFinish={onProfileUpdate} initialValues={initialValues} layout='vertical'>
          <Row gutter={20}>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item label={'Full name'} name='contactName' rules={[{required: true, message: t('first-name-required')}]}>
                <CommonInput inputType='text' onChange={e => setFullNameValue(e.target.value)}/>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item label={'Industry'} name='industry' rules={[{required: true, message: t('industry-field-required')}]}>
                <CommonInput onChange={e => setIndustryValue(e.target.value)}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item label={'E-mail'} name='emailAddress'>
                <CommonInput
                  inputType='email'
                  value={profile.emailAddress}
                  disabled={true}
                  className={styles.disabledInput}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item label={'Phone number'} name='phoneNumber' rules={[{required: true, message: t('number-required')}]}>
                <ReactPhoneInput containerClass={styles.phoneInput} country={'US'} enableAreaCodes={true} onChange={e => setPhoneNumberValue(e)}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item label={<div className={styles.wrapper}>Default location
                <Tooltip title={` This dropdown field allows you to set your preferred location, which will be automatically pre-filled as the default location in all tools when creating reports. You can change the location for individual reports directly in the tool interface.`}>
                  <FontAwesomeIcon icon={faCircleInfo} fontSize={16} style={{marginLeft: '6px'}} />
                </Tooltip>
              </div>} name='defaultLocation'>
                <Select
                  suffixIcon={<CaretDownOutlined onClick={() => setIsDropdownVisible(!isDropdownVisible)} />}
                  className={styles.location}
                  showSearch={true}
                  open={isDropdownVisible}
                  onDropdownVisibleChange={open => setIsDropdownVisible(open)}
                  getPopupContainer={node => node}
                  filterOption={(input, option:any) => (countryCodeToLocationDict[option?.value].toLowerCase().includes(input.toLowerCase()) || option?.value?.includes(input.toLowerCase()))}
                  dropdownClassName='locationDropdown'>
                  {Object.keys(countryCodeToLocationDict)?.map((key, index) => {
                    return <Option key={`country-index-${index}`} value={key} >
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <Flag width={18} height={10} code={key}/>
                        <span style={{marginLeft: '5px'}}>{countryCodeToLocationDict[key]}</span>
                      </div>
                    </Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {!isWhitelabel && referralLink?.length ?
            <Row gutter={14} style={{marginBottom: 24}}>
              <Col xs={24} sm={24} lg={12}>
                <Row justify='space-between'>
                  <div>
                    <AffiliateLinkHeading>FP dashboard link</AffiliateLinkHeading>
                    <AffiliateLink>https://affiliates.searchatlas.com/</AffiliateLink>
                  </div>

                  <ButtonComponent
                    color='blue'
                    style='light-transparent'
                    type='button'
                    onClick={copyLink}
                  ><FontAwesomeIcon icon={faCopy} fontSize={14}/> Copy link</ButtonComponent>
                </Row>
              </Col>
            </Row> : null}
          <div className={styles.flexContainer}>
            <ButtonComponent
              color='primary'
              style='solid'
              type='submit'
              disabled={loading || fullNameValue === profile.contactName && industryValue === profile.industry && phoneNumberValue === profile.phoneNumber}
            >
              {t('save-changes')}
            </ButtonComponent>
          </div>
        </Form>
      </div>
    </Fragment>
  );
});


const AffiliateLinkHeading = styled.div`
color:#4E5156;
font-family: 'Inter', sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 400;
`;
const AffiliateLink = styled.div`
color:#121212;
font-family: 'Inter', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
`;
