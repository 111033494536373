import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@/components/common-components/v2';
import {Modal} from '@/components/common-components/components';
import {Dropdown, Input, Menu, Tooltip} from 'antd';
import {useStore} from '@/store/root-store';
import {useRouter} from 'next/router';
import {getSingleUrlParam} from '@/utils/url';
import moment from 'moment';
import {toJS} from 'mobx';
import {useEffect, useState} from 'react';
import {notification} from '@/utils/notification-v2';
import {LoadingOutlined} from '@ant-design/icons';
import {debounce} from 'lodash';

interface ContentDataListProps {
  idx: number;
  competitorUrl: string;
  businessName: string;
  competitorUrlError: string;
  id?: number;
}

interface Props {
  contentDataList: ContentDataListProps[];
  setContentDataList: (value: ContentDataListProps[]) => void;
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  projectCompetitorsV2: any;
  setProjectCompetitorsV2: any;
}

export const EditCompetitorsModal = observer(({contentDataList, setContentDataList, isVisible, setIsVisible, projectCompetitorsV2, setProjectCompetitorsV2}: Props) => {
  const {
    keywordResearcherV2: {
      keywordRankTracker: {
        addCompetitors,
        addingCompetitors,
        editingCompetitors,
        getProjectCompetitorsV2,
        deleteCompetitor,
        deletingCompetitor,
        projectCompetitors,
        projectCompetitorsPageNumber,
        setProjectCompetitorsPageNumber,
        addMore,
        setAddMore,
        projectList,
        setProjectList,
        getProjectCompetitors,
        editCompetitors,
      },
    },
  } = useStore('');
  const router = useRouter();
  const currentPeriodStart = getSingleUrlParam('periodStart');
  const [deleteId, setDeleteId] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const {periodStart, periodEnd} = router.query;
  const id = parseInt(router.query.id[0]);
  const [filteredList, setFilteredList] = useState([]);

  // eslint-disable-next-line no-useless-escape
  const urlRegex = /^(https?:\/\/)?([a-z0-9\-]+\.)+[a-z]{2,6}(\/[^\s]*)?$/i;
  const isValidURL = url => urlRegex.test(url);

  useEffect(() => {
    const load = async () => {
      const res = await getProjectCompetitors(id, {currentPeriodStart: periodStart as string, currentPeriodEnd: periodEnd as string});
      setFilteredList(res?.results);
    };
    load();
  }, []);

  useEffect(()=> {
    if (!addMore) {
      setProjectList(toJS(projectCompetitors?.results));
    } else if (projectCompetitors?.results && addMore) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      setProjectList([...(projectList?.length ? projectList : []), ...toJS(projectCompetitors?.results)]);
      setAddMore(false);
    }
  }, [JSON.stringify(projectCompetitors?.results)]);

  useEffect(() => {
    if (projectCompetitorsV2?.competitors?.length && isVisible) {
      setContentDataList(toJS(projectCompetitorsV2)?.competitors?.map((item, index) => ({
        idx: index,
        id: item?.id,
        competitorUrl: item?.url,
        businessName: item?.name,
        competitorUrlError: '',
      })));
    }
  }, [isVisible]);

  const onClose = () => {
    setProjectList([]);
    setAddMore(false);
    setProjectCompetitorsPageNumber(1);
    setContentDataList([
      {
        idx: 0,
        competitorUrl: '',
        businessName: '',
        competitorUrlError: '',
      },
    ]);
    setIsVisible(false);
  };

  const updateList = async () => {
    const existingCompetitors = contentDataList?.filter(item => item?.id);
    const newCompetitors = contentDataList?.filter(item => !item?.id);
    const updatedContentDataList = [];
    contentDataList?.forEach(item => {
      if (!item?.competitorUrl) {
        updatedContentDataList?.push({
          ...item,
          ...(!item?.competitorUrl && {competitorUrlError: 'Please enter a competitor URL'}),
        });
      } else {
        updatedContentDataList?.push({
          ...item,
        });
      }
    });
    setContentDataList(updatedContentDataList);
    if (updatedContentDataList?.filter(item => item?.competitorUrlError)?.length) return;
    let response = null;
    if (existingCompetitors?.length) {
      const payload = existingCompetitors?.filter(data => data?.competitorUrl)?.map(item => ({id: item?.id, hostname: item?.competitorUrl, ...(item?.businessName && {name: item?.businessName})}));
      response = await editCompetitors(Number(router?.query?.id[0]), payload);
    }
    if (newCompetitors?.length) {
      const payload = newCompetitors?.filter(data => data?.competitorUrl)?.map(item => ({url: item?.competitorUrl, ...(item?.businessName && {name: item?.businessName})}));
      response = await addCompetitors(Number(router?.query?.id[0]), payload);
    }
    if (response) {
      onClose();
      setTimeout(async () => {
        const res = await getProjectCompetitorsV2(Number(router?.query?.id[0]), {currentPeriodStart, currentPeriodEnd: moment().format('YYYY-MM-DD')});
        setProjectCompetitorsV2(res);
      }, 1000);
    }
  };

  const setInputValue = (value: string, inputFieldName: string, index: number) => {
    const updatedContentDataList = [];
    contentDataList?.forEach(item => {
      if (item?.idx == index) {
        updatedContentDataList?.push({
          ...item,
          [inputFieldName]: value,
          ...(inputFieldName == 'competitorUrl' && (
            value ? (isValidURL(value) ? {competitorUrlError: ''} : {competitorUrlError: 'Please enter a valid URL'}) : {competitorUrlError: 'Please enter a competitor URL'})),
        });
      } else {
        updatedContentDataList?.push(item);
      }
    });
    setContentDataList(updatedContentDataList);
  };

  const removeInputFields = async (id, index) => {
    if (id) {
      setDeleteId(id);
      const response = await deleteCompetitor(Number(router?.query?.id[0]), id);
      if (response?.status == 200) {
        setDeleteId(null);
        if (response?.status == 200) notification.success('', 'Competitor deleted successfully');
        setContentDataList(contentDataList?.filter(item => item.idx !== index));
        await getProjectCompetitorsV2(Number(router?.query?.id[0]), {currentPeriodStart, currentPeriodEnd: moment().format('YYYY-MM-DD')});
      }
    } else {
      setContentDataList(contentDataList?.filter(item => item.idx !== index));
    }
  };

  const addInputField = index => {
    setContentDataList([
      ...contentDataList,
      {
        idx: index,
        competitorUrl: '',
        businessName: '',
        competitorUrlError: '',
      },
    ]);
  };

  const getNextIndex = () => {
    const highestIdx = contentDataList.reduce((max, item) => Math.max(max, item?.idx), -Infinity);
    return highestIdx + 1;
  };

  const competitorsMaxLimitReached = contentDataList?.length >= 4;

  const loadMore = async () => {
    setLoadingMore(true);
    try {
      await getProjectCompetitors(id, {currentPeriodStart: periodStart as string, currentPeriodEnd: periodEnd as string}, true);
      setAddMore(true);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      setLoadingMore(false);
    }
  };

  const uniqueUrls = Array.from(new Set(filteredList?.map(competitor => competitor?.url)));

  const debouncedChange = debounce(async (id, criteria, search) => {
    const res = await getProjectCompetitors(id, criteria, false, search);
    setFilteredList(res?.results);
  }, 500);

  const handleSearchChange = search => debouncedChange(id, {currentPeriodStart: periodStart as string, currentPeriodEnd: periodEnd as string}, search);


  return <StyledModal
    destroyOnClose
    width={'885px'}
    closable={true}
    onClose={() => onClose()}
    visible={isVisible}
    style={{top: 50, marginTop: '10px', marginBottom: '10px'}}
  >
    <ModalContentWrapper>
      <ModalTitle>Competitors</ModalTitle>
      <ModalSubtitle>Choose competitors to see how you stack up, both organically and locally.</ModalSubtitle>
      <HeaderWrapper>
        <HeaderTitle style={{width: '395px'}}>{'Competitor URL'?.toUpperCase()}</HeaderTitle>
        <div style={{width: 'calc(100% - 395px)', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <HeaderTitle>{'Google Business'?.toUpperCase()}</HeaderTitle>
        </div>
      </HeaderWrapper>
      <ContentContainer>
        {contentDataList?.map(item => <div key={item?.idx}>
          <ContentWrapper>
            <Dropdown
              onVisibleChange={() => setFilteredList(projectList)}
              trigger={['click']}
              overlay={
                <MenuWrapper>
                  <MenuStyled>
                    {uniqueUrls.map((url, idx) => {
                      return (
                        <Menu.Item
                          // disabled={contentDataList?.some(comp => comp?.competitorUrl == url)}
                          onClick={() => setInputValue(url as string, 'competitorUrl', item?.idx)}
                          key={idx}
                          style={{backgroundColor: url === item?.competitorUrl ? '#2D6CCA20' : undefined}}
                        >
                          {url as string}
                        </Menu.Item>
                      );
                    })}
                  </MenuStyled>
                  {projectCompetitors?.totalPages !== projectCompetitorsPageNumber &&
                  <LoadMoreContainer>
                    <LoadMoreBtn marginBottom='5px' style={{margin: 'auto'}}
                      loading={loadingMore}
                      onClick={() => {
                        loadMore();
                      }}
                    >Load more</LoadMoreBtn>
                  </LoadMoreContainer>}
                </MenuWrapper>
              }>
              <InputStyled
                value={item?.competitorUrl}
                onChange={async e => {
                  setInputValue(e?.target?.value, 'competitorUrl', item?.idx);
                  handleSearchChange(e?.target?.value);
                }}
                left
              />
            </Dropdown>
            <div style={{width: '1px', backgroundColor: '#D2D2D2', border: '1px solid #D2D2D2', height: '18px'}} />
            <InputStyled value={item?.businessName} onChange={e => setInputValue(e?.target?.value, 'businessName', item?.idx)} right />
            {deletingCompetitor && deleteId == item?.id ?
              <LoadingOutlined style={{fontSize: 14, marginLeft: '8px'}} spin /> :
              <FontAwesomeIcon icon={faTrash} color='#A3A4A4' style={{cursor: 'pointer', marginLeft: '8px'}} onClick={() => removeInputFields(item?.id, item?.idx)} />
            }
          </ContentWrapper>
          <div style={{display: 'flex'}}>
            <div style={{width: '400px', color: 'red', fontSize: '12px', marginBottom: '5px'}}>{item?.competitorUrlError}</div>
          </div>
        </div>)}
      </ContentContainer>
      <Tooltip title={competitorsMaxLimitReached ? 'More than four competitors not allowed' : ''}>
        <AddButton disabled={competitorsMaxLimitReached} onClick={() => !competitorsMaxLimitReached && addInputField(getNextIndex())}>+Add competitor</AddButton>
      </Tooltip>
      <FooterBtns>
        <Tooltip title={contentDataList?.length > 4 ? 'More than four competitors not allowed' : ''}>
          <div>
            <UpdateListButtonStyled
              loading={addingCompetitors || editingCompetitors}
              disabled={addingCompetitors || editingCompetitors || contentDataList?.length > 4 || !!contentDataList?.filter(item => item?.competitorUrlError)?.length}
              background='#2D6CCA'
              onClick={() => updateList()}
            >
              Update list
            </UpdateListButtonStyled>
          </div>
        </Tooltip>
        <CancelButton onClick={() => onClose()}>Cancel</CancelButton>
      </FooterBtns>
    </ModalContentWrapper>
  </StyledModal>;
});

const UpdateListButtonStyled = styled(Button)`
  width: fit-content;
  height: 44px;
  border-radius: 8px;
  padding: 14px 25px;
  margin: 0;
`;

const ModalContentWrapper = styled.div`
  padding: 19px 12px 0px 12px;
`;

const ModalTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const ModalSubtitle = styled.div`
  color: #4E5156;
`;

const AddButton = styled.div<{disabled?: boolean}>`
  width: fit-content;
  color: #2D6CCA;
  cursor: pointer;
  margin-top: 12px;
  ${p => p?.disabled && 'cursor: not-allowed; opacity: 0.5'}
`;

const CancelButton = styled.div`
  width: fit-content;
  color: #4E5156;
  cursor: pointer;
`;

const FooterBtns = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 15px;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  height: 38px;
  border-radius: 8px;
  background-color: #F2F2F5;
  margin-top: 23px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 13px;
`;

const HeaderTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

const ContentContainer = styled.div`
  max-height: 400px;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #F9F9FB;
    border-radius: 10px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const InputStyled = styled(Input)<{left?: boolean; right?: boolean}>`
  width: 395px;
  background-color: #F9F9FB;
  border: 1px solid #D2D2D2;
  ${p => p?.left ? 'border-right' : 'border-left'}: none;
  ${p => p?.left ? 'border-top-left-radius' : 'border-top-right-radius'}: 6px;
  ${p => p?.left ? 'border-bottom-left-radius' : 'border-bottom-right-radius'}: 6px;
`;

const MenuWrapper = styled.div`
  background-color: #FFFFFF;
`;

const MenuStyled = styled(Menu)`
  max-height: 400px;
  overflow: auto;
  border-radius: 6px;
  box-shadow: 0 -4px 6px -4px rgba(0, 0, 0, 0.12), 4px 0 6px -4px rgba(0, 0, 0, 0.12), -4px 0 6px -4px rgba(0, 0, 0, 0.12);

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #F9F9FB;
    border-radius: 10px;
  }
`;

const LoadMoreContainer = styled.div`
  align-items: center;
  display: flex;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  .ant-btn[disabled], .ant-btn[disabled]:hover {
    background-color: transparent;
    border: none;
    color: white;
  }
`;

const LoadMoreBtn = styled(Button)<{marginBottom?: string}>`
  background-color: transparent;
  border: none;
  color: #2D6CCA;
  margin-bottom: ${p => p?.marginBottom ? p?.marginBottom : ''} !important;
  &:hover {
    background: transparent !important;
    border: none !important;
    color: #2D6CCA !important;
    margin-bottom: ${p => p?.marginBottom ? p?.marginBottom : ''} !important;
  }
  &:focus {
    background: transparent !important;
    border: none !important;
    color: #2D6CCA !important;
    margin-bottom: ${p => p?.marginBottom ? p?.marginBottom : ''} !important;
  }
  &:active {
    background: transparent !important;
    border: none !important;
    color: #2D6CCA !important;
    margin-bottom: ${p => p?.marginBottom ? p?.marginBottom : ''} !important;
  }
`;

const StyledModal = styled(Modal)`
  .rc-dialog-content {
    .rc-dialog-body {
      background-color: #F9F9FB;
      border-radius: 10px;
      overflow: hidden !important;
      padding: 23px 18px !important;
      .content-wrapper {
        margin-top: 8px;
      }
    }
    .rc-dialog-close {
    font-size: 21px;
    right: 8px;
    top: 8px;
    font-weight: 700;
    opacity: 1 !important;
    }
  }
  .modal-tabs {
          .ant-tabs-nav {
            margin: 0 0 8px 0 !important;
            &::before {
              border-bottom: 1px solid #D9D9D9 !important;
            }
            .ant-tabs-nav-wrap {
              .ant-tabs-nav-list {
                .ant-tabs-tab-active {
                  .ant-tabs-tab-btn {
                    font-family: 'Inter', sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    color: #121212;
                  }
                }
                .ant-tabs-ink-bar {
                  background-color: #2D6CCA !important;
                  height: 5px !important;
                  border-radius: 4px 4px 0px 0px !important;
                }
                .ant-tabs-tab {
                  .ant-tabs-tab-btn {
                    font-family: 'Inter', sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    color: #4E5156 !important;
                  }
                  &:hover {
                    color: #4E5156 !important;
                  }
                }
              }
            }
          }
          .ant-tabs-content-holder {
            .ant-tabs-content {
              .ant-tabs-tabpane {
                .input-wrapper {
                  display: flex;
                  align-items: center;
                  margin-bottom: 12px;
                  .label {
                    font-family: 'Inter', sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    color: #4E5156;
                  }
                  .ant-col {
                    .text-area {
                      height: 75px;
                      border: 1px solid #D2D2D2;
                      border-radius: 8px;
                      resize: none;
                      &:hover {
                        border: 1px solid #D2D2D2 !important;
                      }
                    }
                    .email-input {
                      height: 38px;
                      border: 1px solid #D2D2D2;
                      border-radius: 8px;
                    }
                    .react-tel-input {
                      .support-phone-input {
                        width: 100% !important;
                        height: 38px !important;
                        border-radius: 8px;

                      }
                      .flag-dropdown {
                        border-radius: 8px 0 0 8px !important;
                      }
                    }  
                  }
                }
              }
            }
          }
        }
`;
