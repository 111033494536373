import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {useEffect, useState} from 'react';
import {Table} from '@/components/common-components';
import styles from './styles.module.scss';
import {useStore} from '@/store/root-store';
import {isNil} from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUp} from '@fortawesome/free-solid-svg-icons';
import {faArrowDown} from '@fortawesome/pro-solid-svg-icons';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';
import {SmallBadge} from '@/components/common-components/components/badge';
import {faArrowUpFromBracket, faCircleInfo} from '@fortawesome/pro-regular-svg-icons';
import {EditCompetitorsModal} from './editCompetitorsModal';
import {Button} from '@/components/common-components/v2';
import {FiltersV2} from '@/components/common-components/v2/filtersV2';
import {getSingleUrlParam} from '@/utils/url';
import {Dropdown, Menu, Radio, Space, Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {useRouter} from 'next/router';
import {downloadExportFile} from '@/utils/api';
import {getApiUrl} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {getSortingKeyAndValue} from '@/utils/filters';

interface Props {
  id: number;
  hideTableHeader?: boolean;
  currentPeriodStart?: string;
  currentPeriodEnd?: string;
}

export const CompetitorsTableV2 = observer(({id, hideTableHeader=false, currentPeriodStart, currentPeriodEnd}: Props) => {
  const {
    keywordResearcherV2: {
      keywordRankTracker: {
        getProjectCompetitorsV2,
        competitorsLoading,
        competitorsSortingKey,
        updateCompetitorsSortingList,
      },
    },
    settings: {customer: {profile: {isLinkgraph}}},
  } = useStore('');
  const {reportBuilder: {details: {setIsKrtCompetitorEmpty, isKrtCompititorEmpty}}} = useStore('');
  const [projectCompetitorsV2, setProjectCompetitorsV2] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [contentDataList, setContentDataList] = useState([
    {
      idx: 0,
      competitorUrl: '',
      businessName: '',
      competitorUrlError: '',
    },
  ]);

  useEffect(() => {
    const load = async () => {
      const res = await getProjectCompetitorsV2(id, {currentPeriodStart, currentPeriodEnd});
      setProjectCompetitorsV2(res);
      if (res.competitors?.length == 0) {
        const rest = isKrtCompititorEmpty?.filter(item=> Number(item.id) != Number(id));
        setIsKrtCompetitorEmpty([...rest, {id: id, haveData: false}]);
      }
    };

    load();
  }, [id]);

  const groupByKeyword = inputArray => {
    const keywordMap = {};

    inputArray?.forEach(item => {
      item?.keywordPositionChange?.forEach(change => {
        const {keyword, location, currentAvgPosition, previousAvgPosition, change: positionChange} = change;

        // If keyword doesn't exist in the map, create a new entry
        if (!keywordMap[keyword]) {
          keywordMap[keyword] = {
            keyword: keyword,
            urlPositionChange: [{
              location: location,
              currentAvgPosition: currentAvgPosition,
              previousAvgPosition: previousAvgPosition,
              change: positionChange,
              url: item.url,
              name: item.name,
            }],
          };
        } else {
          // If keyword exists, add new position data for that keyword
          keywordMap[keyword].urlPositionChange.push({
            location: location,
            currentAvgPosition: currentAvgPosition,
            previousAvgPosition: previousAvgPosition,
            change: positionChange,
            url: item.url,
            name: item.name,
          });
        }
      });
    });

    // Convert the map back into an array of grouped keywords
    return Object.values(keywordMap);
  };

  const [columns, setColumns] = useState([]);
  const [record, setRecord] = useState([]);

  useEffect(() => {
    if (projectCompetitorsV2?.competitors) {
      const newDataFormat = groupByKeyword(projectCompetitorsV2?.competitors);
      setRecord(newDataFormat);
      const urlSet: Set<string> = new Set();
      const tmpColumns = [{
        title: <div style={{marginLeft: newDataFormat?.length ? '' : '20px'}}>{'Keyword'?.toUpperCase()}</div>,
        dataIndex: 'keyword',
        key: 'keyword',
        align: 'left',
        width: 400,
        render: (_, record) => {
          return record.keyword;
        }},
      ];

      newDataFormat.forEach(item => {
        // @ts-ignore
        item.urlPositionChange.forEach((urlPosition: {url: string; name: string}) => {
          if (!urlSet.has(urlPosition.url)) {
            urlSet.add(urlPosition.url);
            tmpColumns.push({
              title: <div style={{textAlign: 'left'}}>
                <Title>{urlPosition.name}</Title>
                <a href={urlPosition?.url?.includes('https://') ? urlPosition.url : `https://${urlPosition.url}/`} target='_blank' rel='noreferrer' style={{color: '#2D6CCA', fontWeight: 400}}>
                  {urlPosition.url}
                </a>
              </div>,
              dataIndex: urlPosition.url,
              key: urlPosition.url,
              align: 'center',
              width: 100,
              render: (_, record) => {
                const data = record.urlPositionChange?.filter(i => i.url == urlPosition.url);
                const positionDelta = (data?.length && !isNil(data[0]?.change) && data[0]?.change != 0 ?
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <FontAwesomeIcon
                      fontSize={8} icon={data[0].change > 0 ? faArrowUp : faArrowDown}
                      color={data[0].change > 0 ? '#1FAC47' : '#F44343'}
                      style={{marginRight: 2}}
                    />
                    <div style={{color: data[0].change > 0 ? '#1FAC47' : '#F44343', fontSize: 12, fontWeight: 500, marginTop: '2px'}}>{Math.abs(data[0].change)}</div>
                  </div> : null);
                return <div style={{width: '100%', display: 'flex', alignItems: 'center', gap: '8px'}}>
                  <span>{(data.length && data[0]?.currentAvgPosition) ? data[0].currentAvgPosition : '-'}</span>
                  {data.length && data[0].change !== 0 ? <div style={{width: 30}}>{positionDelta}</div> : null}
                </div>;
              },
            });
          }
        });
      });
      setColumns(tmpColumns);
    }
  }, [JSON.stringify(projectCompetitorsV2)]);

  const [popoverVisibile, setPopoverVisibile] = useState(false);
  const [fileType, setFileType] = useState('googleSheet');
  const [exporting, setExporting] = useState(false);
  const getPublicHash = () => getSingleUrlParam('public_hash');
  const router = useRouter();

  const getExportLink = async () => {
    setExporting(true);
    const {id} = router.query;
    const {key, value} = competitorsSortingKey ? getSortingKeyAndValue(competitorsSortingKey) : {key: null, value: null};
    const baseUrl = getApiUrl(BaseApi.KEYWORD_ENDPOINT, `/api/v1/rank-tracker/${id}/competitors-report/?period2_start=${currentPeriodStart}&period2_end=${currentPeriodEnd}${key ? `&${key}=${value}` : ''}&${fileType == 'csv' ? 'export=true' : 'export=true&export_to_google_sheets=true'}`);
    try {
      await downloadExportFile(baseUrl, `competitors_table.csv`, fileType == 'googleSheet');
      setExporting(false);
    } catch (error) {
      setExporting(false);
    }
  };

  const antIconWt = <LoadingOutlined style={{fontSize: 16, color: 'white'}} spin />;
  const popoverContent = (
    <PopoverContent >
      <div className='expTitle'>Export data</div>
      <Radio.Group onChange={e=> setFileType(e?.target?.value)}
        value={fileType}>
        <Space direction='vertical'>
          <Radio value={'googleSheet'}>Google Sheets</Radio>
          <Radio value={'csv'}>CSV file</Radio>
        </Space>
      </Radio.Group>
      <FlexWrapper>
        <Button
          style={{backgroundColor: '#7F4EAD', color: '#ffff', justifyContent: 'center'}}
          onClick={() => {
            getExportLink();
            setPopoverVisibile(false);
          }}
        > <div>{exporting ? <Spin indicator={antIconWt} /> : 'Export'}</div>
        </Button>
        <Button
          variant='ghost'
          style={{backgroundColor: '#F9F9FB', color: '#4E5156'}}
          onClick={()=> setPopoverVisibile(false)}
        > <div>Cancel</div>
        </Button>
      </FlexWrapper>
    </PopoverContent>
  );

  const sortList = [
    {name: 'Organic Desktop', active: competitorsSortingKey?.includes('desktop'), value: 'desktop', iconSrc: '/img/google.svg'},
    {name: 'Organic Mobile', active: competitorsSortingKey?.includes('mobile'), value: 'mobile', iconSrc: '/img/google.svg'},
    {name: 'Local Pack', active: competitorsSortingKey?.includes('is_local_pack'), value: 'is_local_pack', iconSrc: '/img/google-places.svg', isComingSoon: true},
    {name: 'Local Search', active: competitorsSortingKey?.includes('is_local_search'), value: 'is_local_search', iconSrc: '/img/google-places.svg', isComingSoon: true},
  ];

  const handleSort = async value => {
    const res = await updateCompetitorsSortingList(id, {currentPeriodStart, currentPeriodEnd}, value);
    setProjectCompetitorsV2(res);
  };

  return <>
    {!hideTableHeader && <>
      <div style={{position: 'relative', marginBottom: '10px', display: 'flex', alignItems: 'center', gap: '10px'}}>
        <div style={{fontSize: '16px', fontWeight: 500, color: '#121212'}}>Competitors</div>
        <FontAwesomeIcon icon={faCircleInfo} color='#A3A4A4' />
        <SmallBadge customStyle={{left: '128px'}} alpha/>
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'end',
        background: '#fff',
        paddingTop: '16px',
        paddingRight: '25px',
      }} className={styles.tableHeader}>
        <FiltersV2
          sortList={sortList}
          onSortChange={handleSort}
          activeSort={competitorsSortingKey}
          isShowSorters={true}
          dateAndCountryPicker={false}
          isDatePicker={false}
          labelWidth='100%'
          varient='blue'
          exportButton={!getPublicHash() ?
            <Dropdown trigger={['click']} placement='bottomLeft' visible={popoverVisibile}
              overlay={
                <Menu style={{width: 220, borderRadius: '10px', padding: '15px'}}>
                  <div>{popoverContent}</div>
                </Menu>
              }>
              <div style={{display: 'flex'}}>
                <ExportButton
                  color='black'
                  loading={exporting}
                  variant='ghost'
                  style={{height: '28px', border: '1px solid #CDCDCD', backgroundColor: 'white', marginRight: '0px'}}
                  onClick={()=> setPopoverVisibile(!popoverVisibile)}
                  disabled={!record?.length}
                >
                  <FontAwesomeIcon icon={faArrowUpFromBracket} style={{fontSize: '12px', color: 'black'}}/>&nbsp;Export
                </ExportButton>
                <ButtonStyled
                  background='#FF8536'
                  onClick={() => setIsVisible(true)}
                >
                  + Edit competitors
                </ButtonStyled>
              </div>
            </Dropdown>: <></>
          }
        />
      </div>
    </>}
    <Wrapper>
      <div className={styles.competitorsTableWrapper}>
        {competitorsLoading ? <RingLoaderV2 height='400px'/> :
          <TableStyled
            columns={columns}
            dataSource={record}
            pagination={false}
            loading={competitorsLoading}
            loadingRows={10}
          />}
      </div>
    </Wrapper>
    {isLinkgraph && isVisible && <EditCompetitorsModal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      contentDataList={contentDataList}
      setContentDataList={setContentDataList}
      projectCompetitorsV2={projectCompetitorsV2}
      setProjectCompetitorsV2={setProjectCompetitorsV2}
    />}
  </>;
});

const Wrapper = styled.div`
  margin-bottom: 50px;
  @media screen and (max-width: 860px) {
    padding: 25px 20px;
  }
  @media screen and (max-width: 700px) {
    padding: 20px 15px;
  }
`;

const TableStyled = styled(Table)`
  table .ant-table-thead th:last-child {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: #121212;
  width: 146px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ButtonStyled = styled(Button)`
  height: 28px;
  border-radius: 6px;
`;

const ExportButton = styled(Button)`
  @media screen and (max-width: 860px) {
    margin-left: 0 !important;
  }
`;

const PopoverContent = styled.div`
  :global(.ant-radio-checked .ant-radio-inner) {
    border-color: $color-purple-medium !important;
  }

  :global(.ant-radio:hover .ant-radio-inner) {
    border-color: $color-purple-medium !important;
  }

  :global(.ant-radio-checked .ant-radio-inner::after) {
    background-color: $color-purple-medium !important;
  }

  .expTitle {
    color: #121212;
    font-size: 15px;
    line-height: 14px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  .explabel {
    color: rgb(163, 164, 164);
    font-size: 12px;
    margin: 8px 0 4px;
  }

  .customWrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: 10px;

    .customInput {
      width: 100%;
      border-radius: 6px;
      border: 1px solid #E8E8E8;
      background: #F9F9FB;
      color: black;
      padding: 4px 8px;
      line-height: 17px;
      &:focus-visible {
        outline: none;
      }
    }
  }  
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10;
  justify-content: center;
  margin-top: 15px;
`;
