import makeInspectable from 'mobx-devtools-mst';
import {initNavBarStore, NavBarStore} from '@/store/nav-bar';
import {initGSCStore, GSCStore} from '@/store/gsc-store';
import {Invoices, initInvoices} from '@/store/invoices';
import {Plans, initPlans} from '@/store/pricing';
import {initOrdersStore, OrdersStore} from '@/store/orders-store';
import {initSiteAuditStore, SiteAuditorStore} from '@/store/site-auditor';
import {initAuditedSitesV2Store, AuditedSitesV2Store} from '@/store/site-auditor-v2';
import {initIssueAnalyzerStore, IssueAnalyzerStore} from '@/store/issue-analyzer/index';
import {initPageSpeedReportStore, PageSpeedReportStore} from '@/store/issue-analyzer/page-speed-report';
import {initIndexibilityReportStore, IndexibilityReportStore} from '@/store/issue-analyzer/indexibility-report';
import {initRedirectsBrokenReportStore, RedirectsBrokenStore} from '@/store/issue-analyzer/redirects-broken-report';
import {initXmlSitemapsReportStore, XmlSitemapsReportStore} from '@/store/issue-analyzer/xml-sitemaps-report';
import {initSettings, Settings} from '@/store/settings';
import {getSingleUrlParam} from '@/utils/url';
import {applySnapshot, Instance, getSnapshot, types} from 'mobx-state-tree';
import {ContentOptimizerStore, initContentOptimizerStore} from './content-optimizer';
import {ContentQualityStore, initContentQualityStore} from './content-quality';
import {useMemo} from 'react';
import {BulkDaCheckerStore, initBulkDaCheckerStore} from './bulk-da-checker';
import {CompetitorExplorerStore, initCompetitorExplorerStore} from './competitor-explorer';
import {KeywordsExplorerStore, initKeywordsExplorerStore} from './keyword-explorer';
import {BackLinkAnalyzerStore, initBackLinkAnalyzerStore} from './backlink-analyzer';
import {LocalSearchResultStore, initLocalSearchResultStore} from './local-search-result';
import {HomeStore, initHomeStore} from './home';
import {initStripe, Stripe} from './stripe';
import {initDomainAnalyzer, DomainAnalyzerStore} from './domain-analyzer';
import {initNotificationsStore, NotificationsStore} from './firebase-notifications-store';
import {OrganicSearchReports, initOrganicSearchReportsStore} from './organic-search';
import {OverviewPageStore, initOverviewPageStore} from './overview-page';
import {PpcSearchReports, initPpcSearchReportsStore} from './ppc-search';
import {LinksReportStore, initLinksReportStore} from './linksReportStore';
import {CreateTasks, initCreateTasks} from './create-tasks';
import {ContentPlannerStore, initContentPlannerStore} from './content-planner';
import {ContentAudit, initContentAudit} from '@/store/content-audit';
import {backlinkResearcherStore, initBacklinkResearcher} from './backlink-researcher';
import {keywordResearcherStore, initKeywordResearcher} from './keyword-researcher';
import {HaroStore, initHaroStore} from './haro-store';
import {OttoStore, initOttoStore} from './otto-store';
import {ContentRewritingStore, initContentRewritingStore} from './content-rewriting';
import {keywordResearcherV2Store, initKeywordResearcherV2Store} from '@/store/keyword-researcher-v2';
import {initLocalSeoScanStore, LocalSeoScanStore} from './local-seo-scan';
import {CompetitorResearcherV2Store, initCompetitorResearcherV2Store} from './competitor-researcher-v2';
import {initDigitalPrStore, digitalPrStore} from './digital-pr';
import {initReportBuilderStore, ReportBuilderStore} from './report-builder';
import {HomeProjectsStore, initHomeProjectsStore} from './home/projects';
import {CampaignStore, initCampaignStore} from './campaign';
import {InboxStore, initInboxStore} from './inbox';
import {BulkUrlAnalyzerStore, initBulkUrlAnalyzerStore} from './bulk-url-analyzer';

import {CountriesStore, initCountriesStore} from './countries';
import {initOttoV2Store, OttoV2Store} from './otto-v2-store';
import {initSemanticGraderStore, SemanticGrader} from './content-optimizer/semantic-grader';
import {initQuestStore, QuestStore} from './quest';

const RootStore = types.model({
  navBar: NavBarStore,
  gsc: GSCStore,
  invoices: Invoices,
  orders: OrdersStore,
  siteAuditor: SiteAuditorStore,
  siteAuditorV2: AuditedSitesV2Store,
  issueAnalyzer: IssueAnalyzerStore,
  pageSpeedReport: PageSpeedReportStore,
  redirectsBrokenReport: RedirectsBrokenStore,
  indexibilityData: IndexibilityReportStore,
  xmlSitemapsReport: XmlSitemapsReportStore,
  settings: Settings,
  contentOptimizer: ContentOptimizerStore,
  semanticGrader: SemanticGrader,
  contentQuality: ContentQualityStore,
  OverviewPage: OverviewPageStore,
  bulkDaChecker: BulkDaCheckerStore,
  localSeoScan: LocalSeoScanStore,
  keywordExplorer: KeywordsExplorerStore,
  competitorExplorer: CompetitorExplorerStore,
  backLinkAnalyzer: BackLinkAnalyzerStore,
  localSearchResult: LocalSearchResultStore,
  plans: Plans,
  home: HomeStore,
  homeProjects: HomeProjectsStore,
  stripe: Stripe,
  domainAnalyzer: DomainAnalyzerStore,
  notifications: NotificationsStore,
  organicSearchReportsStore: OrganicSearchReports,
  ppcSearchReportStore: PpcSearchReports,
  linksReportStore: LinksReportStore,
  createTasksStore: CreateTasks,
  contentAuditStore: ContentAudit,
  contentPlanner: ContentPlannerStore,
  contentRewriting: ContentRewritingStore,
  backlinkResearcher: backlinkResearcherStore,
  keywordResearcher: keywordResearcherStore,
  haroStore: HaroStore,
  ottoStore: OttoStore,
  ottoV2Store: OttoV2Store,
  keywordResearcherV2: keywordResearcherV2Store,
  competitorResearcherV2: CompetitorResearcherV2Store,
  digitalPrStore: digitalPrStore,
  reportBuilder: ReportBuilderStore,
  campaigns: CampaignStore,
  inbox: InboxStore,
  bulkUrlAnalyzer: BulkUrlAnalyzerStore,
  countries: CountriesStore,
  quest: QuestStore,
}).actions(self => {
  let initialState = {};
  return {
    afterCreate: () => {
      initialState = getSnapshot(self);
    },
    reset: () => {
      applySnapshot(self, initialState);
    },
  };
});

export type RootStoreType = Instance<typeof RootStore>;

let store: RootStoreType;

export function resetStore() {
  store.reset();
}

export function initializeStore(snapshot = null) {
  const isSsr = typeof window === 'undefined';
  const domain = isSsr ? '' : getSingleUrlParam('q') ?? '';
  const _store =
    store ??
    RootStore.create({
      navBar: initNavBarStore(domain),
      gsc: initGSCStore(),
      invoices: initInvoices(),
      orders: initOrdersStore(),
      siteAuditor: initSiteAuditStore(),
      siteAuditorV2: initAuditedSitesV2Store(),
      issueAnalyzer: initIssueAnalyzerStore(),
      pageSpeedReport: initPageSpeedReportStore(),
      redirectsBrokenReport: initRedirectsBrokenReportStore(),
      indexibilityData: initIndexibilityReportStore(),
      xmlSitemapsReport: initXmlSitemapsReportStore(),
      settings: initSettings(),
      contentOptimizer: initContentOptimizerStore(),
      semanticGrader: initSemanticGraderStore(),
      contentQuality: initContentQualityStore(),
      bulkDaChecker: initBulkDaCheckerStore(),
      localSeoScan: initLocalSeoScanStore(),
      keywordExplorer: initKeywordsExplorerStore(),
      competitorExplorer: initCompetitorExplorerStore(),
      // TODO: any as a workaround untill we figure out references
      backLinkAnalyzer: initBackLinkAnalyzerStore(),
      plans: initPlans(),
      localSearchResult: initLocalSearchResultStore(),
      home: initHomeStore(),
      homeProjects: initHomeProjectsStore(),
      stripe: initStripe(),
      domainAnalyzer: initDomainAnalyzer(),
      notifications: initNotificationsStore(),
      organicSearchReportsStore: initOrganicSearchReportsStore(),
      OverviewPage: initOverviewPageStore(),
      ppcSearchReportStore: initPpcSearchReportsStore(),
      linksReportStore: initLinksReportStore(),
      createTasksStore: initCreateTasks(),
      contentAuditStore: initContentAudit(),
      contentPlanner: initContentPlannerStore(),
      contentRewriting: initContentRewritingStore(),
      backlinkResearcher: initBacklinkResearcher(),
      keywordResearcher: initKeywordResearcher(),
      haroStore: initHaroStore(),
      ottoStore: initOttoStore(),
      ottoV2Store: initOttoV2Store(),
      keywordResearcherV2: initKeywordResearcherV2Store(),
      competitorResearcherV2: initCompetitorResearcherV2Store(),
      digitalPrStore: initDigitalPrStore(),
      reportBuilder: initReportBuilderStore(),
      campaigns: initCampaignStore(),
      inbox: initInboxStore(),
      bulkUrlAnalyzer: initBulkUrlAnalyzerStore(),
      countries: initCountriesStore(),
      quest: initQuestStore(),
    });
  if (snapshot) {
    applySnapshot(_store, snapshot);
  }
  if (typeof window === 'undefined') {
    return _store;
  }
  if (!store) {
    store = _store;
  }
  makeInspectable(store);
  return store;
}

export function useStore(initialState): Instance<typeof RootStore> {
  return useMemo(() => initializeStore(initialState), [initialState]);
}
